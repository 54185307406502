import { DpeLine, DpeTypeEnergie } from './types';
import Template from '../../assets/json/template-audit.json';
import FilledTemplate from '../../assets/json/template-audit-filled.json';

export type DpeAudit = {
    step2: Record<string, any>;
    step3: Record<string, any>;
    step4: Record<string, any>;
    step6: Record<string, any>;
};

export type DpeLogEntry = {
    level: number;
    message: string;
};
export const dpeLineToAudit = async (dpeLine: DpeLine): Promise<{ DpeAudit: DpeAudit; logs: Array<DpeLogEntry> }> => {
    const logs: Array<DpeLogEntry> = [];

    const DpeAudit = {
        step2: await dpeLineToStep2(dpeLine, logs),
        step3: await dpeLineToStep3(dpeLine, logs),
        step4: await dpeLineToStep4(dpeLine, logs),
        step6: await dpeLineToStep6(dpeLine, logs),
    };

    return { DpeAudit, logs };
};

const dpeLineToStep2 = async (dpeLine: DpeLine, logs: Array<DpeLogEntry>): Promise<Record<string, any>> => {
    const step2: typeof FilledTemplate.step2 = JSON.parse(JSON.stringify(Template.step2));

    console.log('dpeLine step 2 ', JSON.stringify(dpeLine, null, 2));

    logs.push({ level: 1, message: "Recherche d'information générales sur l'habitation" });
    if (dpeLine['Type_bâtiment']) {
        switch (dpeLine['Type_bâtiment']) {
            case 'maison':
                step2.projectType.value = '1';
                step2.projectType.value_label = 'Maison individuelle';
                logs.push({ level: 2, message: 'Type de bâtiment : ' + step2.projectType.value_label });
                break;
            case 'appartement':
                step2.projectType.value = '2';
                step2.projectType.value_label = 'Appartement';
                logs.push({ level: 2, message: 'Type de bâtiment : ' + step2.projectType.value_label });
                break;
            case 'immeuble':
                step2.projectType.value = '3';
                step2.projectType.value_label = 'Immeuble collectif';
                logs.push({ level: 2, message: 'Type de bâtiment : ' + step2.projectType.value_label });
                break;
            default:
                logs.push({ level: 2, message: `Type de bâtiment non reconnu : ${dpeLine['Type_bâtiment']}` });
        }
    }

    if (dpeLine['Année_construction']) {
        step2.houseAge.value = dpeLine['Année_construction'].toString();
        logs.push({ level: 2, message: 'Année de construction : ' + step2.houseAge.value });
    }

    if (dpeLine['Nombre_niveau_logement']) {
        step2.floorCount.value = dpeLine['Nombre_niveau_logement'].toString();
        step2.floorCount.value_label = dpeLine['Nombre_niveau_logement'].toString();
        logs.push({ level: 2, message: 'Nombre de niveau : ' + step2.floorCount.value });
    }

    if (dpeLine['Surface_habitable_logement']) {
        step2.SHab.value = dpeLine['Surface_habitable_logement'];
        step2.SHON.value = dpeLine['Surface_habitable_logement'] * 1.05;

        logs.push({ level: 2, message: 'Surface habitable : ' + step2.SHab.value });
    }

    if (dpeLine['Hauteur_sous-plafond']) {
        step2.buildingPlans.value[0].height = dpeLine['Hauteur_sous-plafond'];
        logs.push({ level: 2, message: 'Hauteur sous plafond : ' + step2.buildingPlans.value[0].height });
    }

    return step2;
};

const dpeLineToStep3 = async (dpeLine: DpeLine, logs: Array<DpeLogEntry>): Promise<Record<string, any>> => {
    const step3 = JSON.parse(JSON.stringify(Template.step3));

    // LA Y A DU MONDE !!!

    // #region section 1 : Énergie de chauffage
    logs.push({ level: 1, message: "Recherche d'information sur l'énergie de chauffage" });

    // "Type d'énergie utilisée pour le chauffage"
    // On va prendre les champs  de l'interface  dans l'ordre...
    if (dpeLine['Type_énergie_principale_chauffage']) {
        switch (dpeLine['Type_énergie_principale_chauffage']) {
            case 'Bois – Bûches':
            case 'Bois – Granulés (pellets) ou briquettes':
            case 'Bois – Plaquettes forestières':
                step3.heaterType.value = '1';
                step3.heaterType.value_label = 'Bois';
                step3.heaterType.included = ['1', '2', '3', '4'];
                logs.push({ level: 2, message: "Type d'énergie de chauffage : Bois" });
                break;

            case 'Fioul domestique':
                step3.heaterType.value = '6';
                step3.heaterType.value_label = 'Fioul';
                step3.heaterType.included = ['1', '2', '3', '4'];
                logs.push({ level: 2, message: "Type d'énergie de chauffage : Fioul" });
                break;
            case 'Gaz naturel':
            case 'Butane':
            case 'Propane':
            case 'GPL':
                step3.heaterType.value = '4';
                step3.heaterType.value_label = 'Gaz';
                step3.heaterType.included = ['1', '2', '3', '4'];
                logs.push({ level: 2, message: "Type d'énergie de chauffage : Gaz" });
                break;
            case 'Réseau de Chauffage urbain':
                step3.heaterType.value = '5';
                step3.heaterType.value_label = 'Réseau de Chauffage urbain';
                step3.heaterType.included = ['3'];
                logs.push({ level: 2, message: "Type d'énergie de chauffage : Réseau de Chauffage urbain" });
                break;
            case 'Électricité':
                if (dpeLine['Catégorie_ENR'] && dpeLine['Catégorie_ENR'] === 'pompe à chaleur') {
                    step3.heaterType.value = '3';
                    step3.heaterType.value_label = 'Elec. thermodynamique (PAC)';
                    step3.heaterType.included = ['1', '3'];
                    logs.push({ level: 2, message: "Type d'énergie de chauffage : Elec. thermodynamique (PAC)" });
                } else {
                    step3.heaterType.value = '0';
                    step3.heaterType.value_label = 'Électrique';
                    step3.heaterType.included = ['1', '2', '3', '4'];
                    logs.push({ level: 2, message: "Type d'énergie de chauffage : Électrique" });
                }
                break;
            case "Électricité d'origine renouvelable utilisée dans le bâtiment":
            case 'Réseau de Froid Urbain':
            case 'Charbon':
                // On a aps d'équivalence !
                break;
        }
    }

    // "Type d’émetteur"
    // La je ne suis aps sur, mais je suppose que l'installation chauffage 1 Est la principale.
    // Donc je vais regarder si il y a un émetteur sur l'installation chamuffage 1.
    if (dpeLine['Type_émetteur_installation_chauffage_n°1']) {
        const emmetteur = dpeLine['Type_émetteur_installation_chauffage_n°1'];
        if (emmetteur.startsWith('Radiateur')) {
            if (emmetteur.includes('avec robinet thermostatique')) {
                step3.heaterFeature.label = 'Radiateur avec vanne thermostatique';
                step3.heaterFeature.value = '4';
                step3.heaterFeature.included = ['1', '3', '4', '5', '6'];
                logs.push({ level: 2, message: "Type d'émetteur : Radiateur avec vanne thermostatique" });
            } else if (emmetteur.includes('sans robinet thermostatique')) {
                step3.heaterFeature.label = 'Radiateur sans vanne thermostatique';
                step3.heaterFeature.value = '5';
                step3.heaterFeature.included = ['1', '3', '4', '5', '6'];
                logs.push({ level: 2, message: "Type d'émetteur : Radiateur sans vanne thermostatique" });
            }
        } else if (emmetteur.includes('(Ventiloconvecteur)')) {
            step3.heaterFeature.label = 'Ventilo convecteur';
            step3.heaterFeature.value = '2';
            step3.heaterFeature.included = ['0', '2'];
            logs.push({ level: 2, message: "Type d'émetteur : Ventilo convecteur" });
        } else if (emmetteur.includes('air soufflé')) {
            step3.heaterFeature.label = 'Air soufflé';
            step3.heaterFeature.value = '3';
            step3.heaterFeature.included = ['3'];
            logs.push({ level: 2, message: "Type d'émetteur : Air soufflé" });
        } else if (emmetteur.startsWith('Plancher')) {
            step3.heaterFeature.label = 'Plancher chauffant';
            step3.heaterFeature.value = '6';
            step3.heaterFeature.included = ['0', '1', '2', '3', '4', '5', '6'];
            logs.push({ level: 2, message: "Type d'émetteur : Plancher chauffant" });
        } else if (emmetteur.startsWith('Poêle')) {
            step3.heaterFeature.label = 'Poêle';
            step3.heaterFeature.value = '7';
            step3.heaterFeature.included = ['1'];
            logs.push({ level: 2, message: "Type d'émetteur : Poêle" });
        } else if (emmetteur.startsWith('Convecteur')) {
            step3.heaterFeature.label = 'Convecteur';
            step3.heaterFeature.value = '1';
            step3.heaterFeature.included = ['0', '1', '2'];
            logs.push({ level: 2, message: "Type d'émetteur : Convecteur" });
        }
    }

    // "Présence d’un système secondaire de chauffage"
    // La, soit on est tout éléctrique, et donc on a pas de chauffage secondaire.
    // Soit on a un chauffage non éléctrique,
    // dans ce cas on a au moins 2 énergies différentes,
    // - électricité et (même que chauffage)
    // - electricité et 'même que chauffage) et un autre - qui peut être électricité aussi ! -

    // On compte les types d'énergies.
    // Pour avoir un système de chauffage secondaire, il aut donc 3 types dénergies.
    let typeEnergyCount = 0;
    if (dpeLine['Type_énergie_n°1']) typeEnergyCount++;
    if (dpeLine['Type_énergie_n°2']) typeEnergyCount++;
    if (dpeLine['Type_énergie_n°3']) typeEnergyCount++;

    let systemeChauffageSecondaire = false;
    if (dpeLine['Type_énergie_générateur_n°1_installation_n°2']) {
        systemeChauffageSecondaire = true;
        logs.push({ level: 2, message: "Présence d'un système de chauffage secondaire" });
    }

    // TYpe d'énergie secondaire
    if (typeEnergyCount === 3) {
        systemeChauffageSecondaire = true;
        step3.heaterHealWholeHouse.value = '1';
        step3.heaterHealWholeHouse.value_label = 'oui';
        for (let i = 1; i <= 3; i++) {
            const entryName = `Type_énergie_n°${i}` as keyof DpeLine;
            if (dpeLine[entryName]) {
                const energyName = dpeLine[entryName] as DpeTypeEnergie;
                // On élimine la même énergie que la principale.
                if (energyName === dpeLine['Type_énergie_principale_chauffage']) continue;
                switch (energyName) {
                    case 'Bois – Bûches':
                    case 'Bois – Granulés (pellets) ou briquettes':
                    case 'Bois – Plaquettes forestières':
                        step3.heaterType2.value = '1';
                        step3.heaterType2.value_label = 'Bois';
                        step3.heaterType2.included = ['1', '2', '3', '4'];
                        logs.push({ level: 2, message: "Type d'énergie de chauffage secondaire : Bois" });
                        break;

                    case 'Fioul domestique':
                        step3.heaterType2.value = '6';
                        step3.heaterType2.value_label = 'Fioul';
                        step3.heaterType2.included = ['1', '2', '3', '4'];
                        logs.push({ level: 2, message: "Type d'énergie de chauffage secondaire : Fioul" });
                        break;
                    case 'Gaz naturel':
                    case 'Butane':
                    case 'Propane':
                    case 'GPL':
                        step3.heaterType2.value = '4';
                        step3.heaterType2.value_label = 'Gaz';
                        step3.heaterType2.included = ['1', '2', '3', '4'];
                        logs.push({ level: 2, message: "Type d'énergie de chauffage secondaire : Gaz" });
                        break;
                    case 'Réseau de Chauffage urbain':
                        step3.heaterType2.value = '5';
                        step3.heaterType2.value_label = 'Réseau de Chauffage urbain';
                        step3.heaterType2.included = ['3'];
                        logs.push({ level: 2, message: "Type d'énergie de chauffage secondaire : Réseau de Chauffage urbain" });
                        break;
                    case 'Électricité':
                        step3.heaterType2.value = '0';
                        step3.heaterType2.value_label = 'Électrique';
                        step3.heaterType2.included = ['1', '2', '3', '4'];
                        logs.push({ level: 2, message: "Type d'énergie de chauffage secondaire : Électrique" });
                        break;
                    case "Électricité d'origine renouvelable utilisée dans le bâtiment":
                    case 'Réseau de Froid Urbain':
                    case 'Charbon':
                        // On a aps d'équivalence !
                        break;
                }
            }
        }
    } else if (typeEnergyCount === 2) {
        // trouver si c'est electricité ou rien.
        if (systemeChauffageSecondaire) {
            // il y a un type de chauffage secondaire !
            step3.heaterHealWholeHouse.value = '1';
            step3.heaterHealWholeHouse.value_label = 'oui';
            if (dpeLine['Type_énergie_générateur_n°1_installation_n°2']) {
                switch (dpeLine['Type_énergie_générateur_n°1_installation_n°2']) {
                    case 'Bois – Bûches':
                    case 'Bois – Granulés (pellets) ou briquettes':
                    case 'Bois – Plaquettes forestières':
                        step3.heaterType2.value = '1';
                        step3.heaterType2.value_label = 'Bois';
                        step3.heaterType2.included = ['1', '2', '3', '4'];
                        logs.push({ level: 2, message: "Type d'énergie de chauffage secondaire : Bois" });
                        break;

                    case 'Fioul domestique':
                        step3.heaterType2.value = '6';
                        step3.heaterType2.value_label = 'Fioul';
                        step3.heaterType2.included = ['1', '2', '3', '4'];
                        logs.push({ level: 2, message: "Type d'énergie de chauffage secondaire : Fioul" });
                        break;
                    case 'Gaz naturel':
                    case 'Butane':
                    case 'Propane':
                    case 'GPL':
                        step3.heaterType2.value = '4';
                        step3.heaterType2.value_label = 'Gaz';
                        step3.heaterType2.included = ['1', '2', '3', '4'];
                        logs.push({ level: 2, message: "Type d'énergie de chauffage secondaire : Gaz" });
                        break;
                    case 'Réseau de Chauffage urbain':
                        step3.heaterType2.value = '5';
                        step3.heaterType2.value_label = 'Réseau de Chauffage urbain';
                        step3.heaterType2.included = ['3'];
                        logs.push({ level: 2, message: "Type d'énergie de chauffage secondaire : Réseau de Chauffage urbain" });
                        break;
                    case 'Électricité':
                        step3.heaterType2.value = '0';
                        step3.heaterType2.value_label = 'Électrique';
                        step3.heaterType2.included = ['1', '2', '3', '4'];
                        logs.push({ level: 2, message: "Type d'énergie de chauffage secondaire : Électrique" });
                        break;
                    case "Électricité d'origine renouvelable utilisée dans le bâtiment":
                    case 'Réseau de Froid Urbain':
                    case 'Charbon':
                        // On a aps d'équivalence !
                        break;
                }
            }
        } else {
            // pas de type de chauffage secondaire !
            step3.heaterHealWholeHouse.value = '1';
            step3.heaterHealWholeHouse.value_label = 'oui';
            logs.push({ level: 2, message: "Présence d'un système de chauffage secondaire : oui" });
        }
    } else {
        step3.heaterHealWholeHouse.value = '2';
        step3.heaterHealWholeHouse.value_label = 'non';
        logs.push({ level: 2, message: "Présence d'un système de chauffage secondaire : non" });
    }

    // la surface chauffée par l'énergie secondaire :
    //let surfaceInstallation1 = 0;
    let surfaceInstallation2 = 0;

    if (dpeLine['Surface_chauffée_installation_chauffage_n°1']) {
        //surfaceInstallation1 = dpeLine['Surface_chauffée_installation_chauffage_n°1'];
    }
    if (dpeLine['Surface_chauffée_installation_chauffage_n°2']) {
        surfaceInstallation2 = dpeLine['Surface_chauffée_installation_chauffage_n°2'];
        step3.heaterSurface.value = surfaceInstallation2;
        logs.push({ level: 2, message: `Surface chauffée par le système de chauffage secondaire : ${surfaceInstallation2}` });
    }
    // #endregion

    // #region section 3 : générateurs

    let generatorCount = 0;
    if (dpeLine['Type_générateur_n°1_installation_n°1']) generatorCount++;
    if (dpeLine['Type_générateur_n°1_installation_n°2']) generatorCount++;
    if (generatorCount === 1 || generatorCount === 2) {
        step3.heaterGeneratorIdenticalNumber.value = generatorCount;
        logs.push({ level: 2, message: `Nombre de générateurs identiques : ${generatorCount}` });
    }

    if (dpeLine['Type_énergie_principale_chauffage']) {
        switch (dpeLine['Type_énergie_principale_chauffage']) {
            case 'Fioul domestique':
            case 'Gaz naturel':
            case 'Butane':
            case 'Propane':
            case 'GPL':
                // Dans ces cas la il se peut que la chaudière soit a condensation.

                if (JSON.stringify(dpeLine).includes('condensation')) {
                    step3.condensingHeater.value = true;
                    logs.push({ level: 2, message: 'Chaudière à condensation' });
                }
                break;
        }
    }
    if (JSON.stringify(dpeLine).includes('condensation')) {
        step3.condensingHeater.value = true;
        logs.push({ level: 2, message: 'Chaudière à condensation' });
    }
    // #endregion

    // #region section 4 : Eau chaude sanitaire

    // Eau chaude sanitaire liée au chauffage
    step3.heaterHotWaterIncluded.value = false;
    if (dpeLine['Type_énergie_principale_ECS']) {
        if (dpeLine['Type_énergie_principale_ECS'] === 'Électricité') {
            step3.heaterHotWaterIncluded.value = false;
            logs.push({ level: 2, message: 'ECS liée au chauffage' });
        } else if (dpeLine['Type_énergie_principale_chauffage'] && dpeLine['Type_énergie_principale_chauffage'] === dpeLine['Type_énergie_principale_ECS']) {
            step3.heaterHotWaterIncluded.value = true;
            logs.push({ level: 2, message: 'ECS liée au chauffage' });
        }
    }

    // Volume
    let volumeECS = 0;
    if (dpeLine['Volume_stockage_générateur_ECS_n°1']) {
        console.log('Volume_stockage_générateur_ECS_n°1', dpeLine['Volume_stockage_générateur_ECS_n°1']);
        volumeECS += dpeLine['Volume_stockage_générateur_ECS_n°1'];
    }
    if (dpeLine['Volume_stockage_générateur_ECS_n°2']) {
        volumeECS += dpeLine['Volume_stockage_générateur_ECS_n°2'];
    }
    step3.heaterHotWaterStorageCapacity.value = volumeECS;
    logs.push({ level: 2, message: `Volume de stockage de l'ECS : ${volumeECS}` });
    if (dpeLine['Catégorie_ENR'] && dpeLine['Catégorie_ENR'] === 'chauffe-eau thermodynamique') {
        step3.heaterHotWaterThermodynamic.value = true;
        logs.push({ level: 2, message: 'Chauffe-eau thermodynamique' });
    }

    if (dpeLine['Production_ecs_solaire_installation']) {
        step3.heaterHotWaterSolar.value = dpeLine['Production_ecs_solaire_installation'];
        logs.push({ level: 2, message: "Production d'ECS solaire" });
    }

    if (dpeLine['Configuration_installation_ECS']) {
        if (dpeLine['Configuration_installation_ECS'] === "Un seul système d'ECS avec solaire") {
            step3.heaterHotWaterSolar.value = true;
            logs.push({ level: 2, message: "Configuration d'ECS : Un seul système d'ECS avec solaire" });
        }
    }

    // #endregion

    // #region section 5 : installation PV

    if (dpeLine['Catégorie_ENR']) {
        if (dpeLine['Catégorie_ENR'] === 'panneaux solaires thermiques' || dpeLine['Catégorie_ENR'] === 'panneaux solaires photovoltaïques') {
            step3.photovoltaique.value = true;
            logs.push({ level: 2, message: "Présence d'une installation PV" });
        }
    }
    if (dpeLine['Présence_production_PV_(0/1)'] === true) {
        step3.photovoltaique.value = true;
        logs.push({ level: 2, message: "Présence d'une installation PV" });
    }

    if (dpeLine['Système_production_électricité_origine_renouvelable']) {
        if (
            dpeLine['Système_production_électricité_origine_renouvelable'].includes('photovoltaïque') ||
            dpeLine['Système_production_électricité_origine_renouvelable'].includes('Photovoltaïque')
        ) {
            step3.photovoltaique.value = true;
            logs.push({ level: 2, message: "Présence d'une installation PV" });
        }
    }

    // Type_installation_solaire

    // #endregion

    // #region section 6 : ventilation

    if (dpeLine['Type_ventilation']) {
        if (dpeLine['Type_ventilation'].includes('double')) {
            step3.ventilationType.label = 'Ventilation mécanique Double flux';
            step3.ventilationType.value = '5';
            logs.push({ level: 2, message: 'Type de ventilation : Ventilation mécanique Double flux' });
        } else if (dpeLine['Type_ventilation'].includes('simple')) {
            if (dpeLine['Type_ventilation'].includes('autoréglable')) {
                step3.ventilationType.label = 'Ventilation mécanique Simple flux autoréglables';
                step3.ventilationType.value = '2';
                logs.push({ level: 2, message: 'Type de ventilation : Ventilation mécanique Simple flux autoréglables' });
            } else if (dpeLine['Type_ventilation'].includes('hygro')) {
                step3.ventilationType.label = 'Ventilation mécanique Simple flux hygroréglable';
                step3.ventilationType.value = '3';
            }
        }
        // peut être qu'on peut généraliser ca:
        // "Type_ventilation": "Ventilation par ouverture des fenêtres" ??
    }
    // #endregion

    return step3;
};

const dpeLineToStep4 = async (dpeLine: DpeLine, logs: Array<DpeLogEntry>): Promise<Record<string, any>> => {
    const step4 = JSON.parse(JSON.stringify(Template.step4));

    logs.push({ level: 1, message: "Recherche d'information sur les coûts de l'énergie" });
    for (let i = 1; i <= 3; i++) {
        const entryName = `Type_énergie_n°${i}` as keyof DpeLine;
        if (dpeLine[entryName]) {
            const cost = dpeLine[`Coût_total_5_usages_énergie_n°${i}` as keyof DpeLine];
            const energyName = dpeLine[entryName] as DpeTypeEnergie;
            switch (energyName) {
                case 'Bois – Bûches':
                case 'Bois – Granulés (pellets) ou briquettes':
                case 'Bois – Plaquettes forestières':
                    step4.energyCostsWood.value = cost;
                    logs.push({ level: 2, message: "Coûts de l'énergie Bois : " + cost });
                    break;

                case 'Fioul domestique':
                    step4.energyCostsFuel.value = cost;
                    logs.push({ level: 2, message: "Coûts de l'énergie Fioul : " + cost });
                    break;
                case 'Gaz naturel':
                case 'Butane':
                case 'Propane':
                case 'GPL':
                    step4.energyCostsGas.value = cost;
                    logs.push({ level: 2, message: "Coûts de l'énergie Gaz : " + cost });
                    break;
                case 'Électricité':
                    step4.energyCostsElectric.value = cost;
                    logs.push({ level: 2, message: "Coûts de l'énergie Électricité : " + cost });
                    break;
                case 'Réseau de Chauffage urbain':
                case "Électricité d'origine renouvelable utilisée dans le bâtiment":
                case 'Réseau de Froid Urbain':
                case 'Charbon':
                    // On a aps d'équivalence !
                    break;
            }
        }
    }

    return step4;
};

const dpeLineToStep6 = async (dpeLine: DpeLine, logs: Array<DpeLogEntry>): Promise<Record<string, any>> => {
    const step6 = JSON.parse(JSON.stringify(Template.step6));

    logs.push({ level: 1, message: "Recherche d'information sur les isolations" });
    if (dpeLine['Qualité_isolation_plancher_haut_comble_aménagé']) {
        //On sait pas trop quoi mettre, mais on est avec des combles aménagés !
        step6.ceilingType.value = '2';
        step6.ceilingType.label = 'Combles aménagés';
        step6.ceilingType.buildingType = ['1', '2', '3', '4'];
        logs.push({ level: 2, message: "Type d'isolation des combles aménagés : 2" });
        // la valeur de la qualité d'isolation
        if (dpeLine['Qualité_isolation_plancher_haut_comble_aménagé'] !== 'insuffisante') {
            //on a une isolation !
            // qu'elle soit bonne ou moyenne
            step6.ceilingInsulationPresence.value = true;
            logs.push({ level: 2, message: "Présence d'une isolation des combles aménagés" });
        }
    }
    if (dpeLine['Qualité_isolation_plancher_haut_comble_perdu']) {
        //On sait pas trop quoi mettre, mais on est avec des combles perdus !
        step6.ceilingType.value = '0';
        step6.ceilingType.label = 'Combles non aménagés';
        step6.ceilingType.buildingType = ['1', '2', '3', '4'];
        // la valeur de la qualité d'isolation
        if (dpeLine['Qualité_isolation_plancher_haut_comble_perdu'] !== 'insuffisante') {
            //on a une isolation !
            // qu'elle soit bonne ou moyenne
            step6.ceilingInsulationPresence.value = true;
            logs.push({ level: 2, message: "Présence d'une isolation des combles perdus" });
        }
    }
    if (dpeLine['Qualité_isolation_plancher_haut_toit_terrase']) {
        //On sait pas trop quoi mettre, mais on est avec une toiture terrasse !
        step6.ceilingType.value = '3';
        step6.ceilingType.label = 'Toiture terrasse';
        step6.ceilingType.buildingType = ['1', '2', '3', '4'];
        logs.push({ level: 2, message: "Présence d'une isolation des toitures terrasse" });
    }

    step6.rampantIsolated.value = dpeLine['Isolation_toiture_(0/1)'] === true;
    logs.push({ level: 2, message: "Présence d'une isolation des rampants" });

    if (dpeLine['Qualité_isolation_plancher_bas']) {
        if (dpeLine['Qualité_isolation_plancher_bas'] !== 'insuffisante') {
            step6.floorInsulationPresence.value = true;
            logs.push({ level: 2, message: "Présence d'une isolation des planchers bas" });
        }
    }

    if (dpeLine['Qualité_isolation_enveloppe']) {
        if (dpeLine['Qualité_isolation_enveloppe'] !== 'insuffisante') {
            step6.exteriorWallInsulationPresence.value = true;
            logs.push({ level: 2, message: "Présence d'une isolation des murs extérieurs" });
        }
    }
    return step6;
};
