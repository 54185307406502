import React, { useEffect, useState } from 'react';
import * as storageService from '../../services/localStorageService';
import { dpeLineToAudit, DpeLogEntry } from '../../services/dpe/dpe';
import './DpeLoader.scss';
import { DpeLine } from '../../services/dpe/types';
import { chercherDernierPrixConnu } from '../../services/foncier/service';
type DpeLoaderProps = {
    dpe: DpeLine;
};

const SLOW = 200;
// const FAST = 50;

const DpeLoader: React.FC<DpeLoaderProps> = (props: DpeLoaderProps) => {
    const [logs, setLogs] = useState<DpeLogEntry[]>([]);
    const [title, setTitle] = useState<string>('Recherche du DPE');
    const [pendingLogs, setPendingLogs] = useState<DpeLogEntry[]>([]);
    const [etiquette, setEtiquette] = useState<string>('');

    useEffect(() => {
        const randomTime = SLOW + Math.floor(Math.random() * 2 * SLOW);
        const interval = setInterval(() => {
            if (pendingLogs.length > 0) {
                setLogs((prevLogs) => [...prevLogs, pendingLogs[0]]);
                setPendingLogs((prevPendingLogs) => prevPendingLogs.slice(1));
            }
        }, randomTime);

        return () => clearInterval(interval);
    }, [pendingLogs]);

    useEffect(() => {
        console.log('useEffect');
        // Intercepte les nouveaux logs via la callback

        chercherDernierPrixConnu().then((mutation) => {
            console.log('mutation', JSON.stringify(mutation, null, 2));
        });
        /**
         * Recherche éventuelle d'un DPE existant.
         */
        dpeLineToAudit(props.dpe).then((result) => {
            let audit = storageService.getAudit();
            audit.step2 = result.DpeAudit.step2;
            audit.step3 = result.DpeAudit.step3;
            audit.step4 = result.DpeAudit.step4;
            audit.step6 = result.DpeAudit.step6;
            storageService.setAudit(audit);
            setTitle('Extraction des données du DPE');
            setEtiquette(props.dpe['Etiquette_DPE'] || '');
            setPendingLogs(result.logs);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="dpe-loader">
            <h2>{title}</h2>
            <ul className="dpe-loader__list">
                {logs.map((log, index) => (
                    <li key={index} className={`dpe-loader__list-item item-level-${log.level}`}>
                        {log.message}
                    </li>
                ))}
            </ul>
            <div className={`etiquette etiquette-${etiquette}`}>
                <span>{etiquette}</span>
            </div>
        </div>
    );
};

export default DpeLoader;
