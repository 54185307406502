import { isCurrentAuditMar } from '../localStorageService';
import { Theme } from './theme';
import { getAllPackAE, getFirstPackMMA, getFirstPanneau, isFenetre, isPorte, isVMC } from './themesTools';
import * as api from '../apiParticulierService';
import { PackageData } from './package';
import * as storageService from '../localStorageService';
import * as themesTools from './themesTools';
// Read me
//
// Ici, j'ai ajouté les règles pour gérer les compatibilité de selection de package, qu'on ne peut pas traiter directement.
// l'utilisateur clique sur le selecteur d'un package, on traite ce clic.
// puis on refait une passe sur toutes les règles (ce qui peut potentiellement annuler son clic)
//

export type PackageSelector = (themes: Array<Theme>) => Array<Theme>;

export type SwalableErrorMessage = { title: string; message: string };

type Rule = (themes: Array<Theme>, referenceThatTrigered: string | undefined) => Promise<{ messages: Array<SwalableErrorMessage>; disableValidation: boolean }>;
/**
 * La fonction qui va boucler sur les règles spéciales.
 * @param themes prends un CLONE des themes. Ce clone sera modifié ET retourné.
 * @returns les themes passés en paramètres, potentiellement modifiés
 */
export const applyPackageSelectorSpecialRules: Rule = async (themes, uuidThatTrigered) => {
    const errors = Array<SwalableErrorMessage>();
    let disableValidation: boolean = false;
    for (const rule of RULES) {
        const x = await rule(themes, uuidThatTrigered);
        errors.push(...x.messages);
        if (x.disableValidation === true) disableValidation = disableValidation || x.disableValidation;
    }
    return { messages: errors, disableValidation };
};

/**
 * Cette règle déselectionne tous les packages de type 'Services/Garantie de revenu solaire' si il n'y a pas de panneau solaire.
 * @param themes prends un CLONE des themes. Ce clone sera modifié ET retourné.
 * @returns les themes passés en paramètres, potentiellement modifiés
 */
const removeMmaIfNoSolarPanel: Rule = async (themes) => {
    const errors = Array<SwalableErrorMessage>();
    const firstPanneau = getFirstPanneau(themes);

    // Si il y a un panneau, qu'on ait ou non une garantie, c'est ok.
    if (firstPanneau !== undefined) return { messages: errors, disableValidation: false };

    const firstMMa = getFirstPackMMA(themes);
    if (firstMMa) {
        errors.push({
            title: 'Garantie de revenu solaire',
            message: 'Une garantie de revenu solaire ne peut être souscrite que si vous avez sélectionné un produit photovoltaïque',
        });
        firstMMa.applicable = false;
    }
    return { messages: errors, disableValidation: false };
};

/**
 * Cette règle vérifie qu'il y a au moins 2 isolations si c'est une rénovation d'ampleur.
 * Si la règle n'est pas vérifiée, un message d'erreur est affiché.
 * @param themes prends un CLONE des themes. Ce clone sera modifié ET retourné.
 * @returns les themes passés en paramètres, potentiellement modifiés
 */
const atLeast2IsolationIfMAR: Rule = async (themes) => {
    const errors = Array<SwalableErrorMessage>();
    if (!isCurrentAuditMar()) return { messages: errors, disableValidation: false };
    let disableValidation = false;
    let firstIsolationCount = 0;
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            // if (subTheme.subThemeType === '')
            for (const subTheme of theme.subThemes!)
                for (const pack of subTheme.packages) {
                    if (pack.applicable && pack.mainProduct.categorie_parent === 'Isolation') firstIsolationCount++;
                }

    if (firstIsolationCount < 2) {
        errors.push({
            title: 'Isolation',
            message: "Au moins deux isolations doivent être sélectionnées dans le cadre de la rénovation d'ampleur",
        });
        disableValidation = true;
    }
    return { messages: errors, disableValidation };
};

const canInfluenceDimentionnementPacAE = (packageThatTrigered: PackageData | undefined): boolean => {
    if (packageThatTrigered === undefined) return false;
    if (isVMC(packageThatTrigered)) return true;
    if (isFenetre(packageThatTrigered)) return true;
    if (isPorte(packageThatTrigered)) return false;

    if (packageThatTrigered.mainProduct.categorie_parent === 'Isolation') return true;

    return false;
};
/**
 * Cette fonction va redimensionner la PAC en fonction des isolations sélectionnées.
 * @param themes les thèmes courant, avec les packages préconisés et sélectionnés. ILS SERONT MODIFIES par la réponse.
 * @param uuidThatTrigered l'uuid du package qui a déclenché cette règle.
 * @returns les messages d'erreurs éventuels, et un boolean indiquant si la validation doit être désactivée.
 */
export const redimenstionnerLaPacEnCasDisolation: Rule = async (themes, uuidThatTrigered) => {
    // Si l'appel n'a pas été déclenché par un package, on continue
    // Si un package a déclenché cette règle, il faut que ce soit une isolation, sinon, on ne fait rien.
    if (uuidThatTrigered !== undefined) {
        // on prend le package, de cette uuid :
        const packageThatTrigered = themesTools.getPackageByUuid(themes, uuidThatTrigered);
        // Si un package a déclenché cette règle, il faut que ce soit une isolation, sinon, on ne fait rien.
        if (!canInfluenceDimentionnementPacAE(packageThatTrigered)) {
            console.log('redimenstionnerLaPac : ' + packageThatTrigered?.reference + " n'a pas d'impact");
            return { messages: [], disableValidation: false };
        }
    }

    const errors = Array<SwalableErrorMessage>();
    // on filtre les pacs AE.
    const pacsAE = getAllPackAE(themes);

    // ATTENTION : SI la logique change dans cette fonction,
    // il faut modifier la fonction redimenstionnerLaPac dans src/services/calculs/filterPreconisation/filterChauffage.ts

    // les packs sont déjà sont déjà triées par puissance croissante.

    // Si il ne reste rien : on return undefined
    // ou si aucune PAC AE n'est sélectionnée, ca signifie qu'on ne souhaite pas de PAC.
    // on va donc pas forcer la selectionner
    const oldSelectedPack = pacsAE.find((p) => p.applicable);

    if (pacsAE.length <= 0 || !oldSelectedPack) {
        console.log('redimenstionnerLaPac : aucune pac');
        return { messages: [], disableValidation: false };
    }

    // A partir de la on va calculer les nouvelles déperditions, intervenues suite aux changement d'isolation.
    const { audit } = storageService.stepListToAuditAndClient();
    // approximation initiale des déperditions
    let deperditions = audit.SHab.value / 10;
    try {
        // C'est la valeur de perte de chaleur par m2 de surface habitable, avant travaux !
        let deperditionBeforeInput: api.EnergyLossInput | undefined = api.exctractAndCheckEnergyLossInfo(audit);
        if (deperditionBeforeInput) {
            // C'est après travaux, avec les packages selectionnés.
            // appel api
            const deperditionAfterOutput = await api.getEnergyLoss(exctractAndCheckEnergyLossInfoWithThemes(deperditionBeforeInput, themes));
            deperditions = deperditionAfterOutput.deperditions;
            console.log('redimenstionnerLaPac : déperditions = ' + deperditions);
        }
    } catch (ex) {
        // don't do anything use our approx.
        console.log('EnergyLoss fail = ' + JSON.stringify(ex, null, 2));
    }
    // On applique le facteur de 1.3 sur les déperditions. réunion avec ylan du 28/11/2024
    deperditions *= 1.3;

    // Maintenant on va selectionner le package PAC selon sa puissance.
    // On a déjà fait tout le travail préparatoire, lors du filtrage des pacsAE.
    let selectedPack: PackageData | undefined = undefined;

    for (const pack of pacsAE) {
        if (pack.puissance === undefined) continue; // théoriquement n'arrive pas.

        // Si la puissance est juste 10% sous la déperdition max, on la prend.
        // c'est a dire si la déperdition est 17, on ne devrait prendre une pac à 22.
        // Mais on tolère si la pac est moins de 10% en dessous => on prendrait la pac a 16.
        let puissanceToleree = pack.puissance * 1.1;

        // selectionner première pac dont la puissance est supérieure au déperditions
        if (puissanceToleree >= deperditions) {
            selectedPack = pack;
            break;
        }
    }

    // On va déselectionner l'ancienne PAC et on selectionne la nouvelle
    // seulement si elles sont différentes.

    if (selectedPack && oldSelectedPack.uuid !== selectedPack.uuid) {
        oldSelectedPack.applicable = false;
        selectedPack.applicable = true;
        // si aucun package n'a déclenché cette règle, on affiche pas de message.
        if (uuidThatTrigered !== undefined)
            errors.push({
                title: 'Redimensionnement de la pompe à chaleur',
                message: "La puissance de la pompe à chaleur a été ajustée en fonction de l'isolation sélectionnée",
            });
    }

    return { messages: errors, disableValidation: false };
};

/**
 * Cette fonction va extraire les données de l'audit, et les modifier en fonction des packages sélectionnés.
 * @param before les données d'entrée pour obtenir les déperditions avant travaux
 * @param themes les packages sélectionnés
 * @returns les données d'entrée pour obtenir les déperditions après travaux
 */
const exctractAndCheckEnergyLossInfoWithThemes = (before: api.EnergyLossInput, themes: Array<Theme>): api.EnergyLossInput => {
    const after = { ...before };

    // surface habitable, year, floorCount, levels, departmentId, consigneTemperature, et altitude ne changent pas,
    // entre avant et après travaux !

    // si before est true, after est true aussi.
    // on ne peux pas être plus mauvais après travaux qu'avant !

    // si les murs sont isolés
    after.murs = before.murs || !!themesTools.getFirstIsolation(themes, 'Mur');
    after.combles = before.combles || !!themesTools.getFirstIsolation(themes, 'Comble') || !!themesTools.getFirstIsolation(themes, 'Rampant');
    after.plancher = before.plancher || !!themesTools.getFirstIsolation(themes, 'Plancher');
    after.doubleVitrage = before.doubleVitrage || !!themesTools.getDoubleVitrage(themes);
    after.hygroreglable = before.hygroreglable || !!themesTools.getVmc(themes);

    return after;
};

const rampantsAndComblesAreMutuallyExclusive: Rule = async (themes, uuidThatTrigered) => {
    if (uuidThatTrigered === undefined) return { messages: [], disableValidation: false };

    const errors = Array<SwalableErrorMessage>();

    // on prend le package, de cet uuid :
    const packageThatTrigered = themesTools.getPackageByUuid(themes, uuidThatTrigered);
    if (packageThatTrigered === undefined) {
        return { messages: [], disableValidation: false };
    }

    if (packageThatTrigered.mainProduct.breadcrumb.includes('Rampant')) {
        const packageCombles = themesTools.getFirstIsolation(themes, 'Comble');
        if (packageCombles) {
            // errors.push({
            //     title: 'Rampants et Combles',
            //     message: 'Les rampants et les combles ne peuvent être sélectionnés en même temps, le package combles sera déselectionné',
            // });
            packageCombles.applicable = false;
        }
    }

    if (packageThatTrigered.mainProduct.breadcrumb.includes('Comble')) {
        const packageRampants = themesTools.getFirstIsolation(themes, 'Rampant');
        if (packageRampants) {
            // errors.push({
            //     title: 'Rampants et Combles',
            //     message: 'Les rampants et les combles ne peuvent être sélectionnés en même temps, le package rampants sera déselectionné',
            // });
            packageRampants.applicable = false;
        }
    }

    return { messages: errors, disableValidation: false };
};

const uneSeuleFenetre: Rule = async (themes, uuidThatTrigered) => {
    if (uuidThatTrigered === undefined) return { messages: [], disableValidation: false };

    const errors = Array<SwalableErrorMessage>();

    // on prend le package, de cette référence :
    const packageThatTrigered = themesTools.getPackageByUuid(themes, uuidThatTrigered);
    if (packageThatTrigered === undefined || !packageThatTrigered.mainProduct.breadcrumb.includes('Fenêtre')) {
        return { messages: [], disableValidation: false };
    }

    // si la fenêtre n'est plus selectionnée, on ne fait rien.
    if (!packageThatTrigered.applicable) return { messages: [], disableValidation: false };

    const fenetres = themesTools.getFenetres(themes);
    // On désactive toutes les autres fenêtres
    for (const fenetre of fenetres) {
        if (fenetre.reference !== packageThatTrigered.reference) {
            fenetre.applicable = false;
        }
    }

    return { messages: errors, disableValidation: false };
};

const uneSeulePacAE: Rule = async (themes, uuidThatTrigered) => {
    if (uuidThatTrigered === undefined) return { messages: [], disableValidation: false };

    const errors = Array<SwalableErrorMessage>();

    // on prend le package, de cette référence :
    const packageThatTrigered = themesTools.getPackageByUuid(themes, uuidThatTrigered);
    if (packageThatTrigered === undefined || !themesTools.isPackAE(packageThatTrigered)) {
        return { messages: [], disableValidation: false };
    }

    // si la fenêtre n'est plus selectionnée, on ne fait rien.
    if (!packageThatTrigered.applicable) return { messages: [], disableValidation: false };

    const pacsAE = themesTools.getAllPackAE(themes);
    // On désactive toutes les autres fenêtres
    for (const pacAE of pacsAE) {
        if (pacAE.uuid !== uuidThatTrigered) {
            pacAE.applicable = false;
        }
    }

    return { messages: errors, disableValidation: false };
};

const RULES: Array<Rule> = [
    removeMmaIfNoSolarPanel,
    rampantsAndComblesAreMutuallyExclusive,
    uneSeuleFenetre,
    uneSeulePacAE,

    atLeast2IsolationIfMAR,
    // après les règles qui pourraient modifier les packages d'isolations
    redimenstionnerLaPacEnCasDisolation,
];
