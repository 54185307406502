import React, { useState } from 'react';
import ModaleProjectionFinanciere from '../ModaleProjectionFinanciere/ModaleProjectionFinanciere';
import { useRecoilState } from 'recoil';

// Style
import '../../pages/audit-simulator/audit-simulator.scss';

// Icons
import { ReactComponent as IconIndependance } from '../../assets/icons/synthese/icon-independance-audit.svg';
import { ReactComponent as IconEconomie } from '../../assets/icons/synthese/icon-economie-audit.svg';
import { ReactComponent as IconRendement } from '../../assets/icons/simulator/icon-rendement.svg';
import { ReactComponent as IconEuro } from '../../assets/icons/simulator/icon-euro-growth.svg';
import { ReactComponent as IconPanel } from '../../assets/icons/simulator/icon-solar-panel.svg';
import { ReactComponent as IconHouse } from '../../assets/icons/simulator/icon-house.svg';

// Components
import * as themesTools from '../../services/calculs/themesTools';
import * as precoService from '../../services/calculs/applyPreconisation';

import { currencyFormat, parseCurrency, percentFormat } from '../../services/tools/TypeHelper';
import { legendList } from './dataHelper';
import { caculSimulationComplete } from '../../services/calculs/simulationComplete';
import { DUREE_PROJETCTION_FINANCIERE } from '../../services/calculs/calculEconomie';
import { themesStateAtom, ticketStateAtom } from '../../services/Recoil/Atom/Themes.atom';
import ModalePvgisGraph from '../ModalePvgisGraph/ModalePvgisGraph';

import { GroupedMesuresDetaillees } from '../../services/enedis/enedis';
import * as storageService from '../../services/localStorageService';
import { dummyInstallationPV } from '../../services/calculs/installationPV';
import ModaleFoncier from '../ModalFoncier/ModaleFoncier';

// Les calculs de projection financières et autres sont réalisés sur 25 ans.

const SHOW_DEBUG = process.env.REACT_APP_MODE_DEBUG === 'true'; // apparaît que si on est en debug

// Liste synthèse économique du grand cartouche jaune.
type CartoucheSyntheseEcoData = { icon: React.ReactElement | string; value: string; label: string; id: number };

const SimulatorContainer: React.FC = () => {
    const [themes] = useRecoilState(themesStateAtom);
    // les states globaux, ici, on ne fait que d ela lecture, donc pas de sets.
    const [ticket] = useRecoilState(ticketStateAtom);

    // const [graphData, setGraphData] = useState<PvGraph | undefined>(undefined);
    // const [isGraphDataReady, setIsGraphDataReady] = useState<boolean>(false);

    // Les states de l'écran.
    const [showModal, setShowModal] = useState<'Financiere' | 'graphConso' | 'foncier' | undefined>(undefined);

    if (themes === undefined || themes.length === 0) return <></>;

    //#region les calculs de toute la simulation.

    // calcul des caractéristiques du panneau selectionné.
    // panneau, peut être undefined si aucun package panneau solaire n'est selectionné.
    // Les calculs suivants en tiennent compte.
    const installation = themesTools.getFirstInstallation(themes) ?? dummyInstallationPV();
    // prettyLog('installation', { ...installation, PVcalcReport: undefined });

    const { depenseInitiale, depenseFinaleApresTravaux, depenseFinaleApresPV, bilanPv, simuPv } = caculSimulationComplete(themes, installation); //, panneau);
    // prettyLog('simuPv', simuPv);
    // prettyLog('bilanPv', bilanPv);
    // prettyLog('depenseInitiale', depenseInitiale);
    // prettyLog('depenseFinaleApresTravaux', depenseFinaleApresTravaux);
    // prettyLog('depenseFinaleApresPV', depenseFinaleApresPV);
    // Calcul du resteAcharge == ce que le client final devra investir, aide déduite == le prix du ticket,
    // le resteAchargee st utilisé pour le calcul du rendement.
    const resteAcharge = parseCurrency(ticket.reste_a_charge);
    // On calcul le bilan économique de sTous les travaux : Toutes les préco selectionnées, SANS le panneau photovoltaique.
    // on ne met pas le depenseFinaleApresPV, car une aprtie des calcul se fait sans.
    // ce dont on aura besoin est dans simuPV et bilanPV.
    let currentProjectionFinanciere: precoService.ProjectionFinanciere = precoService.calculProjectionFinanciereGlobale(
        depenseInitiale,
        depenseFinaleApresTravaux,
        simuPv,
        bilanPv,
        resteAcharge,
        DUREE_PROJETCTION_FINANCIERE
    );

    // prettyLog('currentProjectionFinanciere', currentProjectionFinanciere);
    // La dépense finale selon qu'on ait ou non des PV.
    const depenseFinale = depenseFinaleApresPV ?? depenseFinaleApresTravaux;

    //#endregion

    //#region les sous-composants calculés

    // construit de cartouche 'synthèse economique' avec 3 chiffres clés, indep, ecoSur25 et rendementSur25.
    const buildSyntheseEconomique = () => {
        const list = [getIndep(), getEco25(), getRendment()];
        return list.map((element) => (
            <li className="synthese-eco-list-item" key={element.id}>
                <div className="icon">{element.icon}</div>
                <h5>{element.value}</h5>
                <p>{element.label}</p>
            </li>
        ));
    };
    // construit le sous-cartouche 'independance énergetique' de 'synthèse economique'
    const getIndep = (): CartoucheSyntheseEcoData => {
        return {
            id: 0,
            icon: <IconIndependance />,
            value: bilanPv ? percentFormat(bilanPv.independence) : '0%',
            label: 'Indépendance énergétique électrique',
        };
    };
    // construit le sous-cartouche 'economie sur 25 ans' de 'synthèse economique'
    const getEco25 = (): CartoucheSyntheseEcoData => {
        return {
            id: 1,
            icon: <IconEconomie />,
            value: currencyFormat(currentProjectionFinanciere.economieHorsPV.prix() + currentProjectionFinanciere.economiePV.total),
            label: 'Economie sur 25 ans',
        };
    };
    // construit le sous-cartouche 'rendement' de 'synthèse economique'
    const getRendment = (): CartoucheSyntheseEcoData => {
        return {
            id: 2,
            icon: <IconRendement />,
            value: percentFormat(currentProjectionFinanciere.rendement, 2),
            label: 'Rendement financier',
        };
    };
    // construit le bar graph AVANT
    const buildBeforePanel = () => {
        return (
            <div className="bigline before">
                <h5>Avant</h5>
                <div className="part default">
                    {currencyFormat(depenseInitiale.prixChauffage())}
                    <br />
                    {Math.round(depenseInitiale.chauffage.total()) + ' KWh'}
                    <div className="small-energy-type">{depenseInitiale.chauffage.energieName()}</div>
                </div>
                <div className="part orange">
                    {currencyFormat(depenseInitiale.prixEauChaude())}
                    <br />
                    {Math.round(depenseInitiale.eauChaude.total()) + ' KWh'}
                    <div className="small-energy-type">{depenseInitiale.eauChaude.energieName()}</div>
                </div>
                <div className="part red">
                    {currencyFormat(depenseInitiale.prixAutres())}
                    <br />
                    {Math.round(depenseInitiale.autres.total()) + ' KWh'}
                    <div className="small-energy-type">{depenseInitiale.autres.energieName()}</div>
                </div>
                {depenseInitiale.abonnementElectricite() !== 0 && <div className="part red-dark">{depenseInitiale.abonnementElectricite()} &euro;</div>}
                {depenseInitiale.abonnementGaz() !== 0 && <div className="part gas">{depenseInitiale.abonnementGaz()} &euro;</div>}
                <div className="total">
                    Total: {currencyFormat(depenseInitiale.prix())}
                    <br />
                </div>
            </div>
        );
    };
    // construit le bar graph après TRAVAUX, visible en local ou en dev uniquement.
    const buildAfterTravauxPanel = () => {
        if (!SHOW_DEBUG) return <></>;
        let economieRatio = 0;
        if (depenseInitiale.prix() !== 0) {
            const economie = depenseInitiale.prix() - depenseFinaleApresTravaux.prix();
            economieRatio = economie / depenseInitiale.prix();
        }
        return (
            <div className="bigline after">
                <h5>Après travaux</h5>
                <div className="part green">
                    {percentFormat(economieRatio)}
                    <span>d'économie</span>
                </div>
                <div className="part default">
                    {currencyFormat(depenseFinaleApresTravaux.prixChauffage())}
                    <br />
                    {Math.round(depenseFinaleApresTravaux.chauffage.total()) + ' KWh'}
                    <div className="small-energy-type">{depenseFinaleApresTravaux.chauffage.energieName()}</div>
                </div>
                <div className="part orange">
                    {currencyFormat(depenseFinaleApresTravaux.prixEauChaude())}
                    <br />
                    {Math.round(depenseFinaleApresTravaux.eauChaude.total()) + ' KWh'}
                    <div className="small-energy-type">{depenseFinaleApresTravaux.eauChaude.energieName()}</div>
                </div>
                <div className="part red">
                    {currencyFormat(depenseFinaleApresTravaux.prixAutres())}
                    <br />
                    {Math.round(depenseFinaleApresTravaux.autres.total()) + ' KWh'}
                    <div className="small-energy-type">{depenseFinaleApresTravaux.autres.energieName()}</div>
                </div>
                {depenseFinaleApresTravaux.abonnementElectricite() !== 0 && (
                    <div className="part red-dark">{depenseFinaleApresTravaux.abonnementElectricite()} &euro;</div>
                )}
                {depenseFinaleApresTravaux.abonnementGaz() !== 0 && <div className="part gas">{depenseFinaleApresTravaux.abonnementGaz()} &euro;</div>}
                <div className="total">
                    Total: {currencyFormat(depenseFinaleApresTravaux.prix())} <br />
                </div>
            </div>
        );
    };
    // construit le bar graph après PV
    const buildAfterPvPanel = () => {
        let economieRatio = 0;
        if (depenseInitiale.prix() !== 0) {
            const economie = depenseInitiale.prix() - depenseFinale.prix();
            economieRatio = economie / depenseInitiale.prix();
        }

        const title = 'Après' + (SHOW_DEBUG ? ' PV' : '');
        return (
            <div className="bigline after">
                <h5>{title}</h5>
                <div className="part green">
                    {percentFormat(economieRatio)}
                    <span>d'économie</span>
                </div>
                <div className="part default">
                    {currencyFormat(depenseFinale.prixChauffage())}
                    <br />
                    {Math.round(depenseFinale.chauffage.total()) + ' KWh'}
                    <div className="small-energy-type">{depenseFinale.chauffage.energieName()}</div>
                </div>
                <div className="part orange">
                    {currencyFormat(depenseFinale.prixEauChaude())}
                    <br />
                    {Math.round(depenseFinale.eauChaude.total()) + ' KWh'}
                    <div className="small-energy-type">{depenseFinale.eauChaude.energieName()}</div>
                </div>
                <div className="part red">
                    {currencyFormat(depenseFinale.prixAutres())}
                    <br />
                    {Math.round(depenseFinale.autres.total()) + ' KWh'}
                    <div className="small-energy-type">{depenseFinale.autres.energieName()}</div>
                </div>
                {depenseFinale.abonnementElectricite() !== 0 && <div className="part red-dark">{depenseFinale.abonnementElectricite()} &euro;</div>}
                {depenseFinale.abonnementGaz() !== 0 && <div className="part gas">{depenseFinale.abonnementGaz()} &euro;</div>}
                <div className="total">Total: {currencyFormat(depenseFinale.prix())}</div>
            </div>
        );
    };
    // construit la modale 'projection financière' qui apparait lorsqu'on clique sur le bouton du même nom
    const buildModal = (): JSX.Element | undefined => {
        if (!showModal) return undefined;

        if (showModal === 'foncier') {
            return (
                <ModaleFoncier
                    topTitle={'Données foncières'}
                    title={'Impact de la rénovation sur la valorisation foncière de votre bien'}
                    close={() => {
                        setShowModal(undefined);
                    }}
                    closeTop={true}
                />
            );
        }

        if (showModal === 'graphConso') {
            return (
                <ModalePvgisGraph
                    topTitle={'Projection de votre consommation photovoltaïque'}
                    title={'graph'}
                    close={() => {
                        setShowModal(undefined);
                    }}
                    closeTop={true}
                    // graphData={graphData}
                    simuPv={simuPv}
                />
            );
        }

        if (showModal === 'Financiere')
            return (
                <ModaleProjectionFinanciere
                    topTitle={'Projection financière'}
                    title={'Rendement sur 25 ans'}
                    close={() => {
                        setShowModal(undefined);
                    }}
                    closeTop={true}
                    content={currentProjectionFinanciere}
                />
            );
    };

    //#endregion

    const enedisData: GroupedMesuresDetaillees | undefined = storageService.getAudit().step4?.enedisMesureDetaillee?.value;
    const btnGraphConsoVisible = installation.panneau && enedisData && enedisData.byYear && enedisData.byYear.length !== 0;
    const btnFoncierVisible = true;

    // Le rendu du composant est divisé en 5 parties
    // 1 - le titre (en haut)
    // 2 - le graphique en histogramme avant / après (à gauche)
    // 3 - le cartouche orange de synthèse economique (à droite qui dépasse du cadre)
    // 4 - une ligne légende
    // 5 - bouton pvgis (optionnel, qui ouvre une popup) & bouton projection financière (qui ouvre une popup)

    return (
        <>
            <div className="card card-simulator mb-5">
                {/* 1 - titre */}
                <div className="card-header">
                    <h2>
                        Synthèse des consommations d'énergies
                        <br />
                        <span>Avant / Après rénovation énergetique</span>
                    </h2>
                </div>

                <div className="card-body">
                    <div className="row justify-content-between">
                        {/* 2 - deux histogrammes */}

                        <div className="col-12 col-md-6">
                            <div className="bar-chart">
                                {buildBeforePanel()}
                                {buildAfterTravauxPanel()}
                                {buildAfterPvPanel()}
                            </div>
                        </div>
                        {/* 3 - cartouche de synthèse economique */}

                        <div className="col-12 col-md-6">
                            <div className="synthese-eco">
                                <div className="synthese-eco-title">
                                    <h2>Synthèse économique</h2>
                                </div>

                                <ul className="synthese-eco-list">{buildSyntheseEconomique()}</ul>
                            </div>
                        </div>
                        {/* 4 - légende */}

                        <div className="col-12 col-md-7">
                            <ul className="list-legend">
                                {legendList.map((element) => (
                                    <li className={`list-legend-item ${element.class}`} key={element.id}>
                                        {element.text}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* 5 - bouton de projection financiere */}

                        {
                            <div className="col-12 col-md-5 text-center align-self-end">
                                {btnGraphConsoVisible && (
                                    <button
                                        type="button"
                                        className="btn btn-modal mb-3"
                                        onClick={() => {
                                            setShowModal('graphConso');
                                        }}
                                        disabled={!btnGraphConsoVisible} // visible ou désactivé ?
                                    >
                                        <i className="icon">
                                            <IconPanel fill="#434d5d" />
                                        </i>
                                        <span className="smaller">Projection photovoltaïque</span>
                                    </button>
                                )}

                                <button
                                    type="button"
                                    className="btn btn-modal"
                                    onClick={() => {
                                        setShowModal('Financiere');
                                    }}
                                >
                                    <i className="icon">
                                        <IconEuro fill="#434d5d" />
                                    </i>
                                    <span>Projection financière</span>
                                </button>

                                {btnFoncierVisible && (
                                    <button
                                        type="button"
                                        className="btn btn-modal mt-3"
                                        onClick={() => {
                                            setShowModal('foncier');
                                        }}
                                        disabled={!btnFoncierVisible} // visible ou désactivé ?
                                    >
                                        <i className="icon">
                                            <IconHouse fill="#434d5d" />
                                        </i>
                                        <span>Données foncières</span>
                                    </button>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* appel la construction de la modale */}

            {buildModal()}
        </>
    );
};

export default SimulatorContainer;
