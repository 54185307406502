import { PackagesEntries } from '../apiParticulierService';
import { currencyFormat, parseCurrency } from '../tools/TypeHelper';
import * as api from '../apiParticulierService';
import * as themesService from '../../services/calculs/theme';
import * as themesTools from '../../services/calculs/themesTools';
import { packIsMMA, packIsPV, updatePackagePrice } from './package';
import * as storageService from '../../services/localStorageService';
import { addDays, isBefore, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { getFraisRenoDAmpleur, getMontantAideFraisRenoDAmpleur, getMontantAideRenoDAmpleur } from './aides/aideRenovationDAmpleur';
import { RenovationDAmpleur, RenovationDAmpleurParams } from './aides/aideRenovationDAmpleurDefs';

export type Aide = {
    titre: string;
    titre_long?: string;
    product_name: string;
    product_id: string | undefined;
    montant_lbl: string;
    montant_lbl_abs: string;
    /** Ce montant est négatif ! */
    montant: number;
    // les propriétés qui servent a trier filtrer selon les différents mode des écrans concernés
    isLocale: boolean;
    shouldAppearsInAnnexe: boolean;
    isCompatibleMar: boolean;
    isMarRelated: boolean;
};

export type TicketData = {
    aides: Array<Aide>;
    montant_aides_cumul: string;
    montant_vente: string;
    reste_a_charge: string;

    /** do not use */
    montant_vente_ht: string;
    /** do not use */
    montant_vente_tva: string;
};

export const EMPTY_TICKET: TicketData = {
    aides: [],
    montant_aides_cumul: '0€',
    montant_vente: '0€',
    reste_a_charge: '0€',
    montant_vente_ht: '0€',
    montant_vente_tva: '0€',
};

// Short names
export const AIDE_PANNEAU_SOLAIRE_NAME = "Photovoltaïque prime à l'investissement";
export const AIDE_ECO_PUBLICITAIRE_NAME = 'Aide Eco-publicitaire' as const;
export const AIDE_REMISE_MMA_NAME = 'Remise MMA' as const;
export const AIDE_REMISE_COMMERCIALE_NAME = 'Aide "Nouveaux producteur"' as const;
export const AIDE_REMISE_PREVISITE = 'Remise prévisite' as const;
export const FRAIS_EXPERTISE_RENO_AMPLEUR = "Frais d'expertise" as const;
export const AIDE_FRAIS_EXPERTISE_RENO_AMPLEUR = "Aide Frais d'expertise" as const;
export const AIDE_RENO_AMPLEUR = "Reno d'Ampleur" as const;

// Long names
export const AIDE_PANNEAU_SOLAIRE_LONG = "Aide à l'autoconsommation";
export const AIDE_ECO_PUBLICITAIRE_LONG = 'Contrat Eco-publicitaire' as const;
export const AIDE_REMISE_MMA_LONG = `Remise Assurance MMA "Garantie de Revenue Solaire"` as const;
export const AIDE_REMISE_COMMERCIALE_LONG = 'Remise commerciale "Nouveaux producteur"' as const;
export const AIDE_REMISE_PREVISITE_LONG = 'Remise pour pré-visite' as const;
export const FRAIS_EXPERTISE_RENO_AMPLEUR_LONG = "Frais d'expertise de Renovation d'Ampleur" as const;
export const AIDE_FRAIS_EXPERTISE_RENO_AMPLEUR_LONG = "Aide Frais d'expertise de Renovation d'Ampleur" as const;
export const AIDE_RENO_AMPLEUR_LONG = "Aide Renovation d'Ampleur" as const;

export const localAidesLongNames: { [key in LocaleAideNames]: string } = {
    [AIDE_ECO_PUBLICITAIRE_NAME]: AIDE_ECO_PUBLICITAIRE_LONG,
    [AIDE_REMISE_MMA_NAME]: AIDE_REMISE_MMA_LONG,
    [AIDE_REMISE_COMMERCIALE_NAME]: AIDE_REMISE_COMMERCIALE_LONG,
    [AIDE_REMISE_PREVISITE]: AIDE_REMISE_PREVISITE_LONG,
    [AIDE_PANNEAU_SOLAIRE_NAME]: AIDE_PANNEAU_SOLAIRE_LONG,
    [FRAIS_EXPERTISE_RENO_AMPLEUR]: FRAIS_EXPERTISE_RENO_AMPLEUR_LONG,
    [AIDE_FRAIS_EXPERTISE_RENO_AMPLEUR]: AIDE_FRAIS_EXPERTISE_RENO_AMPLEUR_LONG,
    [AIDE_RENO_AMPLEUR]: AIDE_RENO_AMPLEUR_LONG,
};

export const localAidesNames = [
    AIDE_ECO_PUBLICITAIRE_NAME,
    AIDE_REMISE_MMA_NAME,
    AIDE_REMISE_COMMERCIALE_NAME,
    AIDE_REMISE_PREVISITE,
    AIDE_PANNEAU_SOLAIRE_NAME,
    FRAIS_EXPERTISE_RENO_AMPLEUR,
    AIDE_FRAIS_EXPERTISE_RENO_AMPLEUR,
    AIDE_RENO_AMPLEUR,
];

export const getLongName = (shortName: string | LocaleAideNames): string => {
    return isLocaleAideNames(shortName) ? localAidesLongNames[shortName] : shortName;
};

const isLocaleAideNames = (candidate: string): candidate is LocaleAideNames => {
    return localAidesNames.includes(candidate);
};

export type LocaleAideNames = (typeof localAidesNames)[number];

export type AidesLocalesList = { [key in LocaleAideNames]?: Aide };

export const isDeadLineAideOk = (): boolean => {
    const recoAppointement = storageService.getRecoAppointment();
    if (recoAppointement === null) return false;

    const now = new Date();
    // On prend les 10 premier, comme ca on est a minuit.
    let recoDate = parse(recoAppointement.date.slice(0, 10), 'yyyy-MM-dd', now, { locale: fr });

    // le soir du RDV à minuit.
    recoDate = addDays(recoDate, 1);
    return isBefore(now, recoDate);
};

export const getAppointmentDetails = (): { isAppointmentToday: boolean; recoAppointmentDate: Date | null } => {
    const recoAppointment = storageService.getRecoAppointment();
    if (recoAppointment === null) return { isAppointmentToday: false, recoAppointmentDate: null };

    const now = new Date();
    let recoDate = parse(recoAppointment.date.slice(0, 10), 'yyyy-MM-dd', now, { locale: fr });

    const recoDateWithExtraDay = addDays(recoDate, 1);
    const isAppointmentToday = isBefore(now, recoDateWithExtraDay);
    return { isAppointmentToday, recoAppointmentDate: isAppointmentToday ? recoDate : null };
};

export const remiseCoValue = (pref: api.UserPreferences | undefined | null): number => {
    return !pref ||
        pref.remiseCommerciale === null ||
        pref.remiseCommerciale === undefined ||
        typeof pref.remiseCommerciale !== 'number' ||
        isNaN(pref.remiseCommerciale)
        ? 0
        : pref.remiseCommerciale;
};

// #region create local aide functions

export const createAideEcoPub = (montant: number): Aide | undefined => {
    const aide: Aide = {
        titre: AIDE_ECO_PUBLICITAIRE_NAME,
        product_name: '',
        product_id: undefined,
        montant_lbl: currencyFormat(-montant, true),
        montant_lbl_abs: currencyFormat(Math.abs(-montant), true),
        montant: -montant,
        isLocale: true,
        shouldAppearsInAnnexe: true,
        isCompatibleMar: true,
        isMarRelated: false,
    };
    return aide;
};

export const createAideReventeSurplus = async (themes: Array<themesService.Theme>): Promise<Aide | undefined> => {
    // ici on rajoute à la dure une aide pour l'autoconsommation avec revente en surplus
    const packPv = themesTools.getFirstPackPV(themes);
    if (!packPv) return undefined;

    const montantAide = packPv.installationPV.modeConsommation === 'ReventeSurplus' ? packPv.potentialAideInvestissement : 0;
    if (montantAide === 0) return undefined;
    // on doit ajouter une aide.
    const aideReventeSurplus: Aide = {
        titre: AIDE_PANNEAU_SOLAIRE_NAME,
        product_name: packPv.mainProduct.nom,
        product_id: packPv.mainProduct.product_id.toString(),
        montant_lbl: currencyFormat(-montantAide, true),
        montant_lbl_abs: currencyFormat(Math.abs(-montantAide), true),
        montant: -montantAide,
        isLocale: true,
        shouldAppearsInAnnexe: false,
        isCompatibleMar: true,
        isMarRelated: false,
    };
    return aideReventeSurplus;
};

export const createAideMMA = async (themes: Array<themesService.Theme>): Promise<Aide | undefined> => {
    const deadLineOK = isDeadLineAideOk();
    const allRemises = storageService.getConfig('customerFlowParameters').operationsCommerciales;
    const mmaActivated = allRemises.remiseMMA;

    let aideMma: Aide | undefined;
    if (mmaActivated && deadLineOK) {
        const packmma = themesTools.getFirstPackMMA(themes);
        if (packmma) {
            aideMma = {
                titre: AIDE_REMISE_MMA_NAME,
                product_name: packmma.mainProduct.nom,
                product_id: packmma.mainProduct.product_id.toString(),
                montant_lbl: currencyFormat(-packmma.priceTtc, true),
                montant_lbl_abs: currencyFormat(Math.abs(-packmma.priceTtc), true),
                montant: -packmma.priceTtc,
                isLocale: true,
                shouldAppearsInAnnexe: true,
                isCompatibleMar: false,
                isMarRelated: false,
            };
        }
    }
    return aideMma;
};

export const createAidePrevisit = async (): Promise<Aide | undefined> => {
    const allRemises = storageService.getConfig('customerFlowParameters').operationsCommerciales;

    if (!allRemises || isNaN(allRemises.remisePrevisite) || allRemises.remisePrevisite === 0) return undefined;
    const aidePrevisite: Aide = {
        titre: AIDE_REMISE_PREVISITE,
        product_name: '',
        product_id: undefined,
        montant_lbl: currencyFormat(-allRemises.remisePrevisite, true),
        montant_lbl_abs: currencyFormat(Math.abs(-allRemises.remisePrevisite), true),
        montant: -allRemises.remisePrevisite,
        isLocale: true,
        shouldAppearsInAnnexe: false,
        isCompatibleMar: false,
        isMarRelated: false,
    };
    return aidePrevisite;
};

export const createAideCommerciale = async (): Promise<Aide | undefined> => {
    if (!isDeadLineAideOk()) return undefined;

    const pref = (await api.getMyself()).preferences;

    const value = remiseCoValue(pref);

    if (value === 0) return undefined;

    const aideCo: Aide = {
        titre: AIDE_REMISE_COMMERCIALE_NAME,
        product_name: '',
        product_id: undefined,
        montant_lbl: currencyFormat(-value, true),
        montant_lbl_abs: currencyFormat(Math.abs(-value), true),
        montant: -value,
        isLocale: true,
        shouldAppearsInAnnexe: true,
        isCompatibleMar: false,
        isMarRelated: false,
    };
    return aideCo;
};

/**
 * créé les frais d'expertise de réno d'ampleur. uniqueemnt en parcours ExpertMar
 * Ou si force === true.
 * @param reno paramètres de reno d'ampleur
 * @param params paramètres de reno d'ampleur
 * @param force mettre a true pour calculer hors parcours expertMar (cas de la simulation pour le mail initial)
 * @returns la valeur des frais d'intervention de 'Mon Accompagnateur Renov'
 */
export const createFraisRenoDAmpleur = (reno: RenovationDAmpleur, params: RenovationDAmpleurParams, force: boolean = false): Aide | undefined => {
    if (!storageService.isCurrentAuditMar() && !force) return undefined;
    const montant = getFraisRenoDAmpleur();
    if (montant === undefined || montant === 0) return undefined;

    const aide: Aide = {
        titre: FRAIS_EXPERTISE_RENO_AMPLEUR,
        product_name: '',
        product_id: undefined,
        montant_lbl: currencyFormat(montant, true),
        montant_lbl_abs: currencyFormat(Math.abs(montant), true),
        montant: montant,
        isLocale: true,
        shouldAppearsInAnnexe: true,
        isCompatibleMar: true,
        isMarRelated: true,
    };
    return aide;
};

/**
 * créé l'aide sur les frais d'expertise de réno d'ampleur. uniqueemnt en parcours ExpertMar
 * Ou si force === true.
 * @param reno paramètres de reno d'ampleur
 * @param params paramètres de reno d'ampleur
 * @param force mettre a true pour calculer hors parcours expertMar (cas de la simulation pour le mail initial)
 * @returns la valeur de l'aide pour frais d'intervention de 'Mon Accompagnateur Renov'
 */
export const createAideRenoDAmpleur = (
    reno: RenovationDAmpleur,
    params: RenovationDAmpleurParams,
    ticket: TicketData | undefined,
    force: boolean = false
): Aide | undefined => {
    if (!storageService.isCurrentAuditMar() && !force) return undefined;
    const A = getMontantAideRenoDAmpleur(reno, params);
    if (A === undefined) return undefined;
    const [plafond, ratio] = A;

    const montantHt = ticket ? parseCurrency(ticket.montant_vente_ht) : 0;
    // l'aide se calcul sur le min entre le plafond et le montant ht
    const MontantPlafond = Math.min(plafond, montantHt);

    const montant = reno.elligible ? MontantPlafond * ratio : 0;
    const aide: Aide = {
        titre: AIDE_RENO_AMPLEUR,
        product_name: '',
        product_id: undefined,
        montant_lbl: currencyFormat(-montant, true),
        montant_lbl_abs: currencyFormat(Math.abs(-montant), true),
        montant: -montant,
        isLocale: true,
        shouldAppearsInAnnexe: true,
        isCompatibleMar: true,
        isMarRelated: true,
    };
    return aide;
};
/**
 * créé l'aide pour la réno d'ampleur. uniqueemnt en parcours ExpertMar
 * Ou si force === true.
 * @param reno paramètres de reno d'ampleur
 * @param params paramètres de reno d'ampleur
 * @param force mettre a true pour calculer hors parcours expertMar (cas de la simulation pour le mail initial)
 * @returns la valeur de l'aide pour frais d'intervention de 'Mon Accompagnateur Renov'
 */
export const createAideFraisRenoDAmpleur = (reno: RenovationDAmpleur, params: RenovationDAmpleurParams, force: boolean = false): Aide | undefined => {
    if (!storageService.isCurrentAuditMar() && !force) return undefined;
    const montant = getMontantAideFraisRenoDAmpleur(reno, params);
    if (montant === undefined) return undefined;

    const aide: Aide = {
        titre: AIDE_FRAIS_EXPERTISE_RENO_AMPLEUR,
        product_name: '',
        product_id: undefined,
        montant_lbl: currencyFormat(-montant, true),
        montant_lbl_abs: currencyFormat(Math.abs(-montant), true),
        montant: -montant,
        isLocale: true,
        shouldAppearsInAnnexe: true,
        isCompatibleMar: true,
        isMarRelated: true,
    };
    return aide;
};

//#endregion

/**
 * Utilise un audit id, appelle l'api icoll pour faire une simulation de devis, et construit et retourne un TicketData
 * @param auditId L'id  de l'audit sur lequel on travaille et pour lequel on souhaite un devis.
 * @param packages La liste des packages sélectionnés.
 * @returns Les données de ticket à notre format.
 */
export const simulateDevis = async (auditId: string, packages: PackagesEntries): Promise<TicketData> => {
    try {
        if (auditId === undefined || auditId === '' || packages.length <= 0) {
            // here i get a strange comportement in some case.
            // hard to reproduce, in stand alone demo project.
            // result returned is readonly sometimes.
            //return EMPTY_TICKET;
            //SO updated to this :
            return JSON.parse(JSON.stringify(EMPTY_TICKET)) as TicketData;
        }

        // const devis: Devis = response.response.data as Devis;
        const devis = await api.simulateDevis(+auditId, packages);

        let aides = new Array<Aide>();

        // On construit les aides dans un format qui nous est favorable !
        if (devis['aides-details'] !== undefined)
            aides = devis['aides-details'].map((a) => {
                const montant = -parseCurrency(a.montant);
                const aide: Aide = {
                    titre: a.titre,
                    product_name: a.product_name,
                    product_id: a.product_id.toString(),
                    montant_lbl: currencyFormat(montant, true),
                    montant_lbl_abs: currencyFormat(Math.abs(montant), true),
                    montant: montant,
                    // Ces aides viennent de icoll. Elles ne sont pas locales.
                    // c'est les seuls de ce type normalement.
                    isLocale: false,
                    isCompatibleMar: false,
                    isMarRelated: false,
                    shouldAppearsInAnnexe: false,
                };
                return aide;
            });

        const ticket: Partial<TicketData> = {
            montant_vente: devis.devis.montant_vente,
            aides,
            montant_aides_cumul: devis.aides.montant_aides_cumul,
            reste_a_charge: devis.total.reste_a_charge,
            montant_vente_ht: devis.devis.montant_vente_ht,
            montant_vente_tva: devis.devis.montant_vente_tva,
        };

        return ticket as TicketData;
    } catch (error) {
        console.error(error);
        //throw error;
        return EMPTY_TICKET;
    }
};

/** Chaque carte de package contient une aide qu'on ne connait pas à l'avance
 *  une fois recu le ticket il faut ajouter les aides dans la carte de package, concernée.
 *  Cette fonction permet de le faire.
 *
 * Défaut connu :
 *  les packages contiennent des produits. les aides s'appliquent à des produits, pas à des packages.
 *  Lorsqu'on redistribue les aides, on sait a quel produit elles s'appliquent mais pas à quel package.
 *  Ca Pourrait poser un problème pour les PV. car je crée une aide par l'id. la plupart des packages sont juste une quantité différente du même produit.
 *  L'aide devrait donc s'appliquer sur tous les produits PV. ce qui est le cas.
 *  Mais comme cette aide est calculée après coup, si d'autres aides viennent du ticket sur les PV, on va les écraser. (ou doubler les autres.)
 */

export const populateAllPackageHelp = (ticket: TicketData, allThemesClone: Array<themesService.Theme>): void => {
    const deadLineOK = isDeadLineAideOk();
    const mmaActivated = storageService.getConfig('customerFlowParameters').operationsCommerciales.remiseMMA;
    // pour chaque aide du ticket, on cherche si un package est concerné et on l'applique dessus
    for (const aide of ticket.aides) {
        if (aide.product_id === undefined) continue;
        for (const theme of allThemesClone) {
            for (const subTheme of theme.subThemes!) {
                for (const pack of subTheme.packages) {
                    if (packIsPV(pack)) {
                        const aideMontant = pack.installationPV.modeConsommation === 'ReventeSurplus' ? pack.potentialAideInvestissement : 0;
                        if (aideMontant !== 0) updatePackagePrice(pack, -aideMontant);
                    } else if (packIsMMA(pack)) {
                        if (mmaActivated && deadLineOK) updatePackagePrice(pack, -pack.priceTtc);
                    } else if (pack.mainProduct.product_id.toString() === aide.product_id) {
                        // en cas de plusieurs aides sur le produit. (équivalent d'un +=)
                        updatePackagePrice(pack, pack.totalHelp + aide.montant);
                    }
                }
            }
        }
    }
};

/**
 * Cette fonction calcule les aides qui n'existe pas dans le ticket retourné par le WS.
 * Elle les calcules et les ajoute dans le ticket.
 * @param ticket
 * @param localeAides
 * @param forceIsMar forcer isMar a une valeur. undefined pour déterminer la valeur courante
 */
export const manageLocaleAide = (ticket: TicketData, localeAides: AidesLocalesList, forceIsMar: boolean | undefined = undefined): void => {
    // On retire toutes les aides locales existantes.
    ticket.aides = ticket.aides.filter((a) => !a.isLocale);

    let isMar = storageService.isCurrentAuditMar();
    if (forceIsMar !== undefined) isMar = forceIsMar;

    // on remet les nouvelles.
    for (const name of localAidesNames) {
        const aide = localeAides[name];
        if (aide !== undefined) {
            if (isMar) {
                if (aide.isMarRelated || aide.isCompatibleMar) ticket.aides.push(aide);
            } else {
                if (!aide.isMarRelated) ticket.aides.push(aide);
            }
        }
    }
};

export const recalculTicket = (ticket: TicketData, forceIsMar: boolean | undefined = undefined): void => {
    let totalaide = 0;
    let isMar = storageService.isCurrentAuditMar();
    if (forceIsMar !== undefined) isMar = forceIsMar;

    //console.log('recalculTicket : ');
    ticket.aides.forEach((aide) => {
        //console.log('  ...' + e.montant);
        //totalaide += aide.montant;

        if (aide !== undefined) {
            if (isMar) {
                if (aide.isMarRelated || aide.isCompatibleMar) totalaide += aide.montant;
            } else {
                if (!aide.isMarRelated) totalaide += aide.montant;
            }
        }
    });
    //console.log('=> ' + totalaide);

    // On supprime le trop percu
    let total = parseCurrency(ticket.montant_vente);
    // PArfois ca peut crasher.
    // Un comportement étrange difficile à reproduire,quand getDevis retourne EMPTY_TICKET
    // Alors on a ajouté un try catch
    try {
        ticket.montant_aides_cumul = currencyFormat(totalaide, true);
        // on s'assure que le reste à charge ne soit jamais négatif (même si ça n'arrive jmais.)
        ticket.reste_a_charge = currencyFormat(Math.max(total + totalaide, 0), true);
    } catch (e) {
        // When happens, check if audit id exists.
        console.log('AuditSimulator, recalculTicket, Something gone very very bad here.');
    }
};
