import { PackageEntry, PackagesEntries } from '../apiParticulierService';
import { getPanneauFromPackage, PanneauPhotovoltaique } from './calculPhotovoltaique';
import { isPackAE } from './filterPreconisation/filterChauffageTools';
import { InstallationPV } from './installationPV';
import { packIsPV, PackageData, PackageData_PaneauPhotovoltaique, isBattery } from './package';
import { isKnownCategories } from './products';
import { Theme } from './theme';
export { isPackAE, isPackAA } from './filterPreconisation/filterChauffageTools';

//#region chercher dans les themes

export const getPackageByUuid = (themes: Array<Theme>, uuid: string): PackageData | undefined => {
    for (const theme of themes) for (const subTheme of theme.subThemes!) for (const pack of subTheme.packages) if (pack.uuid === uuid) return pack;
    return undefined;
};

export function clearSelectedPackages(themes: Array<Theme>) {
    for (const theme of themes) {
        for (const subTheme of theme.subThemes!) for (const pack of subTheme.packages) pack.applicable = false;
    }
}

export const getSelectedPackagesWithQty = (themes: Array<Theme>): PackagesEntries => {
    const result: PackagesEntries = [];
    for (const theme of themes)
        for (const subTheme of theme.subThemes!) for (const pack of subTheme.packages) if (pack.applicable) result.push(getPackageWithQty(pack));
    return result;
};

export const getPackageWithQty = (pack: PackageData): PackageEntry => {
    const result: PackageEntry = {
        package_id: pack.icollId,
        produits: [
            {
                product_id: +pack.mainProduct.product_id,
                quantite: '' + pack.mainProduct.quantite,
                surface: '' + pack.mainProduct.quantite,
            },
        ],
    };
    if (pack.poseProduct) {
        // Si il y a un produit de pose, la pose comprend tout et ne contient pas de surface !
        result.produits.push({
            product_id: +pack.poseProduct.product_id,
            quantite: '' + pack.poseProduct.quantite,
            surface: '0',
        });
    }

    return result;
};

export const getFirstPackPV = (themes: Array<Theme>): PackageData_PaneauPhotovoltaique | undefined => {
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Photovoltaïque')
                    for (const pack of subTheme.packages) {
                        if (pack.applicable && packIsPV(pack)) return pack;
                    }

    return undefined;
};

export const getFirstPackMMA = (themes: Array<Theme>): PackageData | undefined => {
    for (const theme of themes)
        if (theme.themeType === 'Services')
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Garantie de revenu solaire')
                    for (const pack of subTheme.packages) {
                        if (pack.applicable) return pack;
                    }

    return undefined;
};

export const getFirstPackBattery = (themes: Array<Theme>): PackageData | undefined => {
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Batterie')
                    for (const pack of subTheme.packages) {
                        if (pack.applicable && isBattery(pack)) {
                            console.log(' batterie selectionnée : ' + pack.reference + '  ' + pack.puissance);
                            return pack;
                        }
                    }

    return undefined;
};
export const getFirstPanneau = (themes: Array<Theme>): PanneauPhotovoltaique | undefined => {
    const pack = getFirstPackPV(themes);
    if (!pack || !pack.mainProduct || !pack.mainProduct.quantite) return undefined;
    return getPanneauFromPackage(pack);
};

export const getFirstInstallation = (themes: Array<Theme>): InstallationPV | undefined => {
    const pack = getFirstPackPV(themes);
    return pack ? pack.installationPV : undefined;
};

export const REGEX_ASSURANCE_MMA = /assurancemma/;

export const hasInsurance = (themes: Array<Theme>): boolean => {
    for (const theme of themes) {
        for (const subTheme of theme.subThemes!) {
            for (const pack of subTheme.packages) {
                if (!pack.applicable) continue;
                if (pack.reference.match(REGEX_ASSURANCE_MMA)) return true;
            }
        }
    }
    return false;
};
const TVA_MAX = 20;
export const hasFullTVA = (themes: Array<Theme>): boolean => {
    for (const theme of themes) {
        for (const subTheme of theme.subThemes!) {
            for (const pack of subTheme.packages) {
                if (!pack.applicable) continue;
                //console.log('TVA ' + pack.reference + ' : ' + pack.mainProduct.tva + '  /  ' + pack.poseProduct?.tva);
                if (pack.mainProduct.tva !== TVA_MAX) return false;
                if (pack.poseProduct && pack.poseProduct.tva !== TVA_MAX) return false;
            }
        }
    }
    return true;
};

export const getAllPackAE = (themes: Array<Theme>): Array<PackageData> => {
    const result = new Array<PackageData>();
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Chauffage')
                    for (const pack of subTheme.packages) {
                        if (isPackAE(pack)) result.push(pack);
                    }
    return result;
};

export const getFirstIsolation = (themes: Array<Theme>, type: 'Mur' | 'Comble' | 'Rampant' | 'Plancher'): PackageData | undefined => {
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Isolation')
                    for (const pack of subTheme.packages) {
                        if (pack.applicable && pack.mainProduct.categorie_parent === 'Isolation') {
                            // On fait un includes parce que c'est pas toujours homogène avec ou sans s ....
                            if (pack.reference.includes('ISO-' + type.toUpperCase())) return pack;
                            if (pack.mainProduct.breadcrumb.includes(type)) return pack;
                        }
                    }

    return undefined;
};

export const getDoubleVitrage = (themes: Array<Theme>): PackageData | undefined => {
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Porte et fenêtre')
                    for (const pack of subTheme.packages) {
                        // La on considère que tous les packages fenètres ont au moins des doubles vitrages
                        if (pack.mainProduct.breadcrumb.includes('Fenêtres') && pack.applicable) return pack;
                    }
    return undefined;
};

export const isVMC = (pack: PackageData | undefined): boolean => {
    if (pack === undefined) return false;
    if (pack.themeLb !== "Economie d'énergie/Ventilation") return false;

    // du plus fort au moins fort
    if (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie === 'VMC') return true;
    if (pack.mainProduct.breadcrumb.includes('VMC')) return true;
    if (pack.mainProduct.categorie_parent === 'Ventilation') return true;
    if (pack.reference.startsWith('VMC-')) return true;

    return false;
};
export const getVmc = (themes: Array<Theme>): PackageData | undefined => {
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Ventilation')
                    for (const pack of subTheme.packages) {
                        // La on considère que tous les packages VMC sont au moins hygorreglables
                        if (pack.applicable) return pack;
                    }
    return undefined;
};

export const isFenetre = (pack: PackageData | undefined): boolean => {
    if (pack === undefined) return false;
    if (pack.themeLb !== "Economie d'énergie/Porte et fenêtre") return false;

    if (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie === 'Fenêtres') return true;
    if (pack.mainProduct.breadcrumb.includes('Fenêtre')) return true;
    if (pack.reference.includes('Fenêtre')) return true;

    return false;
};
export const getFenetres = (themes: Array<Theme>): Array<PackageData> => {
    const result = new Array<PackageData>();
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Porte et fenêtre')
                    for (const pack of subTheme.packages) {
                        if (pack.applicable && pack.mainProduct.breadcrumb.includes('Fenêtre')) result.push(pack);
                    }
    return result;
};

export const isPorte = (pack: PackageData | undefined): boolean => {
    if (pack === undefined) return false;
    if (pack.themeLb !== "Economie d'énergie/Porte et fenêtre") return false;

    if (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie === 'Portes') return true;
    if (pack.mainProduct.breadcrumb.includes('Porte')) return true;
    if (pack.reference.includes('Porte')) return true;

    return false;
};

//#endregion
