import React, { useEffect, useState, useMemo } from 'react';
import { PropertyType } from '../../services/localStorageService';
import * as storageService from '../../services/localStorageService';

import * as Yup from 'yup';
import { Formik } from 'formik';

import { ReactComponent as IconClientInformations } from '../../assets/icons/icon-client-informations.svg';
import { ReactComponent as IconAddress } from '../../assets/icons/icon-address.svg';

import PlacesAutocomplete, { geocodeByPlaceId, getLatLng, geocodeByAddress, Suggestion } from 'react-places-autocomplete';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Select from 'react-select';
import {
    childrenList,
    civilityList,
    contractTypeList,
    employerTypeList,
    maritalStatusList,
    searchOptions,
    searchOptionsPostalCode,
    publicCompanyList,
} from './ClientInformationsEnums';
import { ROUTE_AUDIT_INFO_HABITATION } from '../../routing/paths';
import { getAltitude } from '../../services/tools/mapTools';
import { useStepNavigation } from '../../routing/StepNavigationContext';
import * as api from '../../services/apiParticulierService';
import { renderDefaultValueSelect } from '../../services/tools/selectValue';
import DpeLoader from '../../components/DpeLoader/DpeLoader';
import { DpeLine } from '../../services/dpe/types';

const ClientInformations: React.FC = () => {
    const { validateThenRoute, returnToHome } = useStepNavigation();
    /**
     * Gestion désactivation formulaire
     */
    const [auditSent, setAuditSent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const step1Values = storageService.getAudit().step1;

    useEffect(() => {
        /**
         * Scroll de la page en haut
         */
        window.scrollTo(0, 0);

        /**
         * Vérification si Audit déjà envoyé
         */
        const template = storageService.getAudit();
        if (template?.auditSent) {
            setAuditSent(true);
        }
    }, []);

    const AlertSwal = withReactContent(Swal);

    const initialValues = useMemo(() => {
        return {
            userMaritalStatus: storageService.checkPropertyExistThenCreateOrRender(1, 'userMaritalStatus', `Statut marital`, PropertyType['basic']),
            userCivility: storageService.checkPropertyExistThenCreateOrRender(1, 'userCivility', `Civilité`, PropertyType['basic']),
            userLastname: storageService.checkPropertyExistThenCreateOrRender(1, 'userLastname', `Nom`, PropertyType['basic']),
            userFirstname: storageService.checkPropertyExistThenCreateOrRender(1, 'userFirstname', `Prénom`, PropertyType['basic']),
            userBirthday: storageService.checkPropertyExistThenCreateOrRender(1, 'userBirthday', `Date de naissance`, PropertyType['basic']),
            partnerCivility: storageService.checkPropertyExistThenCreateOrRender(1, 'partnerCivility', 'Civilité du conjoint', PropertyType['basic']),
            partnerLastname: storageService.checkPropertyExistThenCreateOrRender(1, 'partnerLastname', `Nom du conjoint`, PropertyType['basic']),
            partnerFirstname: storageService.checkPropertyExistThenCreateOrRender(1, 'partnerFirstname', `Prénom du conjoint`, PropertyType['basic']),
            partnerBirthday: storageService.checkPropertyExistThenCreateOrRender(1, 'partnerBirthday', `Date de naissance du conjoint`, PropertyType['basic']),
            userHomeTelephone: storageService.checkPropertyExistThenCreateOrRender(1, 'userHomeTelephone', `Téléphone domicile`, PropertyType['basic']),
            userTelephone: storageService.checkPropertyExistThenCreateOrRender(1, 'userTelephone', `Téléphone portable`, PropertyType['basic']),
            userEmail: storageService.checkPropertyExistThenCreateOrRender(1, 'userEmail', `Courriel`, PropertyType['basic']),
            children: storageService.checkPropertyExistThenCreateOrRender(1, 'children', `Nombre de personnes à charge`, PropertyType['withLabel']),
            taxIncome: storageService.checkPropertyExistThenCreateOrRender(1, 'taxIncome', `Revenu fiscal (foyer ou déclarant 1)`, PropertyType['basic']),
            taxIncome2: storageService.checkPropertyExistThenCreateOrRender(1, 'taxIncome2', `Revenu fiscal (déclarant 2)`, PropertyType['basic']),
            taxUsed: storageService.checkPropertyExistThenCreateOrRender(1, 'taxUsed', `Dépenses C.I ou aides perçues`, PropertyType['basic']),
            userAddress: storageService.checkPropertyExistThenCreateOrRender(1, 'userAddress', `Adresse postale`, PropertyType['basic']),
            userZipCode: storageService.checkPropertyExistThenCreateOrRender(1, 'userZipCode', `Code postal`, PropertyType['basic']),
            userLocality: storageService.checkPropertyExistThenCreateOrRender(1, 'userLocality', `Ville`, PropertyType['basic']),
            departmentOriginal: storageService.checkPropertyExistThenCreateOrRender(1, 'departmentOriginal', `Département`, PropertyType['basic']),
            department: storageService.checkPropertyExistThenCreateOrRender(1, 'department', `Département`, PropertyType['withLabel']),
            userAddressManual: storageService.checkPropertyExistThenCreateOrRender(1, 'userAddressManual', `Adresse (auto)`, PropertyType['boolean']),
            taxNoticeUserRefFiscal: storageService.checkPropertyExistThenCreateOrRender(1, 'taxNoticeUserRefFiscal', `Référence fiscal`, PropertyType['basic']),
            taxNoticeNoAvis: storageService.checkPropertyExistThenCreateOrRender(1, 'taxNoticeNoAvis', `N° de l’avis`, PropertyType['basic']),
            taxNoticeUserRefFiscalTwo: storageService.checkPropertyExistThenCreateOrRender(
                1,
                'taxNoticeUserRefFiscalTwo',
                `Référence fiscal`,
                PropertyType['basic']
            ),
            taxNoticeNoAvisTwo: storageService.checkPropertyExistThenCreateOrRender(1, 'taxNoticeNoAvisTwo', `N° de l’avis`, PropertyType['basic']),
            maPrimeRenovLogin: storageService.checkPropertyExistThenCreateOrRender(1, 'maPrimeRenovLogin', `Identifiant`, PropertyType['basic']),
            maPrimeRenovPassword: storageService.checkPropertyExistThenCreateOrRender(1, 'maPrimeRenovPassword', `Mot-de-passe`, PropertyType['basic']),
            maPrimeRenovCheck: storageService.checkPropertyExistThenCreateOrRender(
                1,
                'maPrimeRenovCheck',
                `Le Compte maPrimeRenov a été créé`,
                PropertyType['boolean']
            ),
            dependentDetails: storageService.checkPropertyExistThenCreateOrRender(
                1,
                'dependentDetails',
                `Nombre de personne à charge`,
                PropertyType['dependentDetail']
            ),

            // Action logement
            contractType: storageService.checkPropertyExistThenCreateOrRender(1, 'contractType', `Type de contrat`, PropertyType['withLabel']),
            employerType: storageService.checkPropertyExistThenCreateOrRender(1, 'employerType', `Type d'employeur`, PropertyType['withLabel']),
            publicCompany: storageService.checkPropertyExistThenCreateOrRender(1, 'publicCompany', `Entreprise publique`, PropertyType['withLabel']),
        };
    }, []);

    const nameRegex = /^[-\p{L}', ]+$/u;

    const validationSchema = Yup.object().shape(
        {
            userMaritalStatus: Yup.string().required(`Le statut marital est obligatoire`),
            userCivility: Yup.string().required(`La civilité est obligatoire`),
            userLastname: Yup.string()
                .min(2, 'Le nom doit être composé de 2 caractères minimum')
                .matches(nameRegex, 'Le champ ne doit pas contenir de caractères spéciaux ou de chiffres')
                .required(`Le nom est obligatoire`),
            userFirstname: Yup.string()
                .min(2, 'Le prénom doit être composé de 2 caractères minimum')
                .matches(nameRegex, 'Le champ ne doit pas contenir de caractères spéciaux ou de chiffres')
                .required(`Le prénom est obligatoire`),
            userBirthday:
                storageService.getAuditCampagne() === 'reste_a_charge'
                    ? Yup.date().required(`La date de naissance du déclarant 1 est obligatoire`)
                    : Yup.date().notRequired(),
            partnerCivility: Yup.string().when('userMaritalStatus', {
                is: (value: string) => ['2', '3', '4'].includes(value),
                then: Yup.string().required('La civilité du conjoint est obligatoire'),
            }),
            partnerLastname: Yup.string().when('userMaritalStatus', {
                is: (value: string) => ['2', '3', '4'].includes(value),
                then: Yup.string().required('Le nom du conjoint est obligatoire'),
            }),
            partnerFirstname: Yup.string().when('userMaritalStatus', {
                is: (value: string) => ['2', '3', '4'].includes(value),
                then: Yup.string().required('Le prénom du conjoint est obligatoire'),
            }),
            partnerBirthday:
                storageService.getAuditCampagne() === 'reste_a_charge'
                    ? Yup.date().when('userMaritalStatus', {
                          is: (value: string) => ['2', '3', '4'].includes(value),
                          then: Yup.date().required('La date de naissance du déclarant 2 est obligatoire'),
                      })
                    : Yup.date().notRequired(),
            userHomeTelephone: Yup.string()
                .notRequired()
                .nullable()
                .matches(/^((01)|(02)|(03)|(04)|(05)|(09))[0-9]{8}$/g, 'Le numéro doit inclure 10 chiffres et commencer par 01 à 09')
                .typeError('Le format est erroné'),
            userTelephone: Yup.string()
                .matches(/^((06)|(07))[0-9]{8}$/g, 'Le numéro doit inclure 10 chiffres et commencer par 06 ou 07')
                .required(`Le numéro de téléphone mobile est obligatoire`),
            userEmail: Yup.string()
                .matches(new RegExp('^[^àáâäãåąčćęèéêëėîïíłñńôöòóõùúûüųýżźçČĆ]*$'), 'Les caractères accentués ne sont pas autorisés dans les emails') // sans accent
                .email('Le courriel doit être de type nom@domaine.com')
                .required(`Le courriel est obligatoire`),
            children: Yup.string().required(`Le nombre de personnes à charge est obligatoire`),
            dependentDetails:
                storageService.getAuditCampagne() === 'reste_a_charge'
                    ? Yup.array()
                          .required()
                          .of(
                              Yup.object().shape({
                                  dependentName: Yup.string().required(`Le nom de la personne à charge est obligatoire`),
                                  dependentFirstName: Yup.string().required(`Le prénom de la personne à charge est obligatoire`),
                                  dependentAge: Yup.date().required(`L'âge de la personne à charge est obligatoire`),
                              })
                          )
                    : Yup.array().notRequired(),
            taxIncome: Yup.number()
                .min(0, `Le revenu fiscal doit être supérieur ou égal à 0`)
                .typeError(`Le revenu fiscal est obligatoire`)
                .required(`Le revenu fiscal est obligatoire`),
            taxIncome2: Yup.number()
                .nullable()
                .notRequired()
                .when('userMaritalStatus', {
                    is: (value: string) => ['4'].includes(value),
                    then: Yup.number()
                        .min(0, `Le revenu fiscal doit être supérieur ou égal à 0`)
                        .typeError(`Le revenu fiscal est obligatoire`)
                        .required(`Le revenu fiscal est obligatoire`),
                }),
            taxUsed: Yup.number().min(0, `Le montant doit être supérieur à 0`).typeError(`Le montant doit être supérieur à 0`).notRequired().nullable(),
            userAddress: Yup.string().required(`L'adresse postale est obligatoire`),
            userZipCode: Yup.string().required(`Le code postal est obligatoire`).nullable(),
            userLocality: Yup.string().required(`La ville est obligatoire`).nullable(),
            departmentOriginal: Yup.string().required(`Le département est obligatoire`).typeError(`Le département est obligatoire`),
            department: Yup.string().notRequired().nullable(),

            taxNoticeUserRefFiscal:
                storageService.getAuditCampagne() === 'reste_a_charge'
                    ? Yup.string()
                          .required(`Le numéro fiscal du déclarant est obligatoire`)
                          .test('taxNoticeUserRefFiscal', 'Le numéro fiscal du déclarant doit inclure 13 caractères avec des chiffres', function (value) {
                              if (!!value) {
                                  const schema = Yup.string().matches(
                                      /([0-9]{13,13})/,
                                      'Le numéro fiscal du déclarant doit inclure 13 caractères avec des chiffres'
                                  );
                                  return schema.isValidSync(value);
                              }
                              return true;
                          })
                          .ensure()
                          .when('taxNoticeNoAvis', {
                              is: (element: any) => element?.length > 0,
                              then: Yup.string()
                                  .required(`La référence de l'avis fiscal est requis et doit comporter 13 chiffres`)
                                  .matches(/([0-9]{13,13})/, "La référence de l'avis fiscal est doit inclure 13 caractères avec des chiffres"),
                          })
                    : Yup.string().notRequired(),

            taxNoticeUserRefFiscalTwo:
                storageService.getAuditCampagne() === 'reste_a_charge'
                    ? Yup.string()
                          .nullable()
                          .notRequired()
                          .when('userMaritalStatus', {
                              is: (value: string) => value === '4',
                              then: Yup.string()
                                  .required(`Le numéro fiscal du déclarant 2 est obligatoire`)
                                  .nullable()
                                  .test(
                                      'taxNoticeUserRefFiscal',
                                      'Le numéro fiscal du déclarant doit inclure 13 caractères avec des chiffres',
                                      function (value) {
                                          if (!!value) {
                                              const schema = Yup.string().matches(
                                                  /([0-9]{13,13})/,
                                                  'Le numéro fiscal du déclarant doit inclure 13 caractères avec des chiffres'
                                              );
                                              return schema.isValidSync(value);
                                          }
                                          return true;
                                      }
                                  )
                                  .ensure()
                                  .when('taxNoticeNoAvisTwo', {
                                      is: (element: any) => element?.length > 0,
                                      then: Yup.string()
                                          .required(`La référence de l'avis fiscal 2 est requis et doit comporter 13 chiffres`)
                                          .matches(/([0-9]{13,13})/, "La référence de l'avis fiscal 2 doit inclure 13 caractères avec des chiffres"),
                                  }),
                          })
                    : Yup.string().notRequired().nullable(),

            taxNoticeNoAvis:
                storageService.getAuditCampagne() === 'reste_a_charge'
                    ? Yup.string()
                          .required(`La référence de l'avis fiscal est obligatoire`)
                          .test('taxNoticeNoAvis', "La référence de l'avis fiscal doit inclure 13 caractères avec des chiffres", function (value) {
                              if (!!value) {
                                  const schema = Yup.string().matches(/([0-9]{13,13})/, 'Le format attendu doit inclure 13 caractères avec des chiffres');
                                  return schema.isValidSync(value);
                              }
                              return true;
                          })
                          .ensure()
                          .when('taxNoticeUserRefFiscal', {
                              is: (element: any) => element?.length > 0,
                              then: Yup.string()
                                  .required(`Le numéro fiscal du déclarant est requis et doit comporter 13 chiffres`)
                                  .matches(/([0-9]{13,13})/, 'Le numéro fiscal du déclarant doit inclure 13 caractères avec des chiffres'),
                          })
                    : Yup.string().notRequired().nullable(),

            taxNoticeNoAvisTwo:
                storageService.getAuditCampagne() === 'reste_a_charge'
                    ? Yup.string()
                          .notRequired()
                          .when('userMaritalStatus', {
                              is: (value: string) => value === '4',
                              then: Yup.string()
                                  .required(`La référence de l'avis fiscal 2 est obligatoire`)
                                  .nullable()
                                  .test('taxNoticeNoAvisTwo', "La référence de l'avis fiscal 2 doit inclure 13 caractères avec des chiffres", function (value) {
                                      if (!!value) {
                                          const schema = Yup.string().matches(
                                              /([0-9]{13,13})/,
                                              'Le format attendu doit inclure 13 caractères avec des chiffres'
                                          );
                                          return schema.isValidSync(value);
                                      }
                                      return true;
                                  })
                                  .ensure()
                                  .when('taxNoticeUserRefFiscalTwo', {
                                      is: (element: any) => element?.length > 0,
                                      then: Yup.string()
                                          .required(`Le numéro fiscal du déclarant 2 est requis et doit comporter 13 chiffres`)
                                          .matches(/([0-9]{13,13})/, 'Le numéro fiscal du déclarant doit inclure 13 caractères avec des chiffres'),
                                  }),
                          })
                    : Yup.string().notRequired().nullable(),
            contractType:
                storageService.getAuditCampagne() === 'action_logement'
                    ? Yup.string().required(`Le type de contrat est obligatoire`)
                    : Yup.string().notRequired(),
            employerType:
                storageService.getAuditCampagne() === 'action_logement'
                    ? Yup.string()
                          .notRequired()
                          .when('contractType', {
                              is: (value: string) => ['0', '1'].includes(value),
                              then: Yup.string().required(`Le type d'employeur est obligatoire`),
                          })
                    : Yup.string().notRequired(),
            publicCompany: Yup.string()
                .notRequired()
                .when('employerType', {
                    is: (value: string) => value === '1',
                    then: Yup.string().required('Entreprise publique est obligatoire'),
                }),
            maPrimeRenovLogin: storageService.getAuditCampagne()
                ? Yup.string().notRequired()
                : Yup.string()
                      .notRequired()
                      .test('maPrimeRenovLogin', `L'identifiant maPrimeRenov’ est obligatoire`, function (value) {
                          if (!!value) {
                              const schema = Yup.string().min(6).matches(/(.*)/, 'Le format attendu doit être un identifiant ou courriel');
                              return schema.isValidSync(value);
                          }
                          return true;
                      })
                      .ensure()
                      .when('maPrimeRenovPassword', {
                          is: (element: any) => element?.length > 0,
                          then: Yup.string().required(`L'identifiant maPrimeRenov’ est obligatoire`),
                      }),
            maPrimeRenovPassword: storageService.getAuditCampagne()
                ? Yup.string().notRequired()
                : Yup.string()
                      .notRequired()
                      .test('maPrimeRenovPassword', `Le mot de passe maPrimeRenov’ est obligatoire`, function (value) {
                          if (!!value) {
                              const schema = Yup.string().min(4).matches(/(.*)/, 'Le format attendu doit être un mot de passe');
                              return schema.isValidSync(value);
                          }
                          return true;
                      })
                      .ensure()
                      .when('maPrimeRenovLogin', {
                          is: (element: any) => element?.length > 0,
                          then: Yup.string().required(`Le mot de passe maPrimeRenov’ est obligatoire`),
                      }),
            maPrimeRenovCheck: Yup.boolean(),
        },
        [
            ['taxNoticeUserRefFiscal', 'taxNoticeNoAvis'],
            ['taxNoticeUserRefFiscalTwo', 'taxNoticeNoAvisTwo'],
            ['maPrimeRenovLogin', 'maPrimeRenovPassword'],
        ]
    );

    const [infoAddress, setInfoAddress] = useState<any>({
        address: storageService.checkPropertyExistThenCreateOrRender(1, 'userAddressAutocomplete', `Adresse (auto)`, PropertyType['basic'])
            ? storageService.checkPropertyExistThenCreateOrRender(1, 'userAddressAutocomplete', `Adresse (auto)`, PropertyType['basic'])
            : '',
    });

    /**
     *  Montrer déclarant2 dans l'avis d'imposition si concubinage
     */
    const showDeclarant2 = (values: any) => {
        if (storageService.getAuditCampagne() !== 'action_logement' && values.userMaritalStatus === '4') {
            return true;
        }
    };

    /**
     * Pour le changement de couleurs du react-select
     */
    const colourStyles = {
        option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#76ca4f' : null,
                color: isFocused ? '#ffffff' : '#1F2F3D',
            };
        },
    };

    /**
     * Modification de la valeur du champ adresse
     * @param address
     */
    const handleChangeAddress = (address: string) => {
        setInfoAddress({ address });
    };

    /**
     * Modification de la valeur du champ code postal
     * @param userZipCode (code postal retourné)
     * @param setValues (Setter Formik)
     * @param values (valeur de formik)
     */
    const handleChangePostalCode = (userZipCode: string, setValues: any, values: any) => {
        /**
         * Mise à jour champ MaPrimeRenov' mot de passe
         */
        if (values.maPrimeRenovPassword === '' && !storageService.getAuditCampagne()) {
            storageService.setAuditValue(
                1,
                true,
                'maPrimeRenovPassword',
                values.userFirstname !== '' && userZipCode !== '' ? `${values.userFirstname + userZipCode}@` : ''
            );
        }

        setValues({
            ...values,
            userZipCode,
            maPrimeRenovPassword:
                values.maPrimeRenovPassword === ''
                    ? values.userFirstname !== '' && userZipCode !== ''
                        ? `${values.userFirstname.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + userZipCode}@`
                        : ''
                    : values.maPrimeRenovPassword,
        });

        setInfoAddress({ address: '' });
        storageService.setAuditValue(1, true, 'department', userZipCode, values.departmentOriginal);
        let departmentOriginal = values.departmentOriginal;
        let department = values.department;

        const domTom: string[] = ['971', '972', '973', '974', '975', '976', '977', '978', '984', '986', '987', '988', '989'];
        const checkDomTomZipCode = domTom.find((element: string) => element.includes(userZipCode.substring(0, 3)));

        if (checkDomTomZipCode && userZipCode.includes(checkDomTomZipCode)) {
            departmentOriginal = checkDepartement(checkDomTomZipCode);

            setValues({
                ...values,
                departmentOriginal,
                department,
                userZipCode,
            });

            // Set in localStorage
            storageService.setAuditValue(1, true, 'userZipCode', userZipCode);
            storageService.setAuditValue(1, true, 'departmentOriginal', departmentOriginal);
            storageService.setAuditValue(1, true, 'department', userZipCode, departmentOriginal);
        }
    };

    /**
     * Fonction pour retourner les informations filtrées de Google Maps
     * @param gmapsData
     * @param typeOfData
     */
    const returnInfoAddress = (gmapsData: any, typeOfData: string) => {
        const info = gmapsData.address_components.filter((obj: any) => {
            return obj.types[0] === typeOfData;
        });
        if (info.length > 0 && info[0].long_name) {
            return info[0]?.long_name;
        }
        return null;
    };

    // DOM TOM
    const checkDepartement = (postalCode: string | undefined) => {
        switch (postalCode) {
            case '971':
                return 'Guadeloupe';

            case '972':
                return 'Martinique';

            case '973':
                return 'Guyane française';

            case '974':
                return 'La Réunion';

            case '975':
                return 'Saint-Pierre-et-Miquelon';

            case '976':
                return 'Mayotte';

            case '977':
                return 'Saint-Barthélemy';

            case '978':
                return 'Saint-Martin';

            case '984':
                return 'Terres australes et antarctiques françaises';

            case '986':
                return 'Wallis-et-Futuna';

            case '987':
                return 'Polynésie française';

            case '988':
                return 'Nouvelle-Calédonie';

            case '989':
                return 'Clipperton';

            default:
                return;
        }
    };

    /**
     * Function pour obtenir les informations de geocoding de l'adresse postale saisie
     * @param placeId (ID de l'emplacement)
     * @param setValues (Setter Formik)
     * @param values (valeur de formik)
     */
    const handleSelectAddress = async (placeId: string, setValues: any, values: any) => {
        if (placeId) {
            await geocodeByPlaceId(placeId)
                .then((results) => {
                    const gmapsData = results[0];

                    /**
                     * Mise à jour du champ adresse complète
                     */
                    setInfoAddress({ address: gmapsData.formatted_address });

                    // Définition des informations de l'adresse postale
                    const streetNumber = returnInfoAddress(gmapsData, 'street_number');
                    const route = returnInfoAddress(gmapsData, 'route');
                    const userZipCode = returnInfoAddress(gmapsData, 'postal_code');
                    let departmentOriginal = returnInfoAddress(gmapsData, 'administrative_area_level_2');
                    const userLocality = returnInfoAddress(gmapsData, 'locality');
                    let department = values.department;

                    const domTom: string[] = ['971', '972', '973', '974', '975', '976', '977', '978', '984', '986', '987', '988', '989'];
                    const checkDomTomZipCode = domTom.find((element: string) => element.includes(userZipCode.substring(0, 3)));

                    // Ajout d'un contrôle dans le cas d'un département vide
                    if (!departmentOriginal) {
                        departmentOriginal = returnInfoAddress(gmapsData, 'administrative_area_level_1');

                        if (!departmentOriginal) {
                            geocodeByAddress(userZipCode).then((results) => {
                                departmentOriginal = returnInfoAddress(results[0], 'administrative_area_level_2');
                            });
                        }
                    }

                    // Si le département fait partie des DOM TOM, forcer le département
                    if (userZipCode.includes(checkDomTomZipCode)) {
                        departmentOriginal = checkDepartement(checkDomTomZipCode);
                    }

                    const userAddress = `${streetNumber ? streetNumber + ' ' : ''}${route}`;

                    /**
                     * Injection des informations d'adresse dans le local storage
                     */
                    storageService.setAuditValue(1, true, 'userAddress', userAddress);
                    storageService.setAuditValue(1, true, 'userZipCode', userZipCode);
                    storageService.setAuditValue(1, true, 'departmentOriginal', departmentOriginal);
                    storageService.setAuditValue(1, true, 'userLocality', userLocality);
                    storageService.setAuditValue(1, true, 'userAddressAutocomplete', gmapsData.formatted_address);
                    storageService.setAuditValue(1, true, 'department', userZipCode, departmentOriginal);

                    /**
                     * Mise à jour champ MaPrimeRenov' mot de passe
                     */
                    if (values.maPrimeRenovPassword === '' && !storageService.getAuditCampagne()) {
                        storageService.setAuditValue(
                            1,
                            true,
                            'maPrimeRenovPassword',
                            values.userFirstname !== '' && userZipCode !== '' ? `${values.userFirstname + userZipCode}@` : ''
                        );
                    }

                    /**
                     * Mise à jour du formulaire
                     */
                    setValues({
                        ...values,
                        userAddress,
                        userZipCode,
                        departmentOriginal,
                        userLocality,
                        department,
                        maPrimeRenovPassword:
                            values.maPrimeRenovPassword === ''
                                ? values.userFirstname !== '' && userZipCode !== ''
                                    ? `${values.userFirstname.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + userZipCode}@`
                                    : ''
                                : values.maPrimeRenovPassword,
                    });

                    /**
                     * Injection de la latitude et longitude et de l'altitude dans le local storage
                     */
                    getLatLng(gmapsData).then((result) => {
                        storageService.checkPropertyExistThenCreateOrRender(1, 'userAddressLatitude', `Latitude`, PropertyType['basic']);
                        storageService.checkPropertyExistThenCreateOrRender(1, 'userAddressLongitude', `Longitude`, PropertyType['basic']);

                        storageService.setAuditValue(1, true, 'userAddressLatitude', result.lat);
                        storageService.setAuditValue(1, true, 'userAddressLongitude', result.lng);

                        getAltitude(result).then((altitude) => {
                            storageService.setAuditValue(1, true, 'altitude', altitude);
                        });
                    });

                    api.searchDpe(userAddress, userZipCode, userLocality).then((dpe) => {
                        if (!dpe || (Array.isArray(dpe) && dpe.length === 0)) {
                            console.log('pas de DPE pour cette adresse', JSON.stringify(dpe, null, 2));
                            return;
                        }
                        const TheDpe: DpeLine = Array.isArray(dpe) ? dpe[0] : dpe;
                        AlertSwal.fire({
                            html: <DpeLoader dpe={TheDpe} />,
                            width: '80%',
                            confirmButtonText: 'Fermer',
                            focusConfirm: true,
                            customClass: {
                                confirmButton: 'btn btn-primary min-width',
                            },
                        });
                    });
                })
                .catch(() => {
                    AlertSwal.fire({
                        title: `Recherche de l'adresse`,
                        text: `L'adresse postale est introuvable, merci de remplir les informations manuellement`,
                        icon: 'error',
                        width: 550,
                        confirmButtonText: 'Fermer',
                        focusConfirm: true,
                        customClass: {
                            confirmButton: 'btn btn-primary min-width',
                        },
                    });
                });
        }
    };

    useEffect(() => {
        const fetchMunicipality = async () => {
            if (step1Values.userZipCode.value) {
                try {
                    const municipalityData = await api.getMunicipality(step1Values.userZipCode.value as string);
                    const municipality = municipalityData.features[0]?.properties?.citycode;
                    localStorage.setItem('insee', municipality);
                } catch (error) {
                    console.error('Erreur lors de la récuperation de la municipalité :', error);
                }
            }
        };

        fetchMunicipality();
    }, [step1Values.userZipCode]);

    /**
     * Function pour obtenir les informations de geocoding de l'adresse postale saisie
     * @param placeId (ID de l'emplacement)
     * @param setValues (Setter Formik)
     * @param values (Suggestion en provenance de Google Maps)
     * @param isValid (Status de validité du formulaire)
     */
    const handlePostalCodeLocality = (placeId: string, setValues: any, values: any, isValid: boolean) => {
        if (placeId) {
            geocodeByPlaceId(placeId)
                .then((results) => {
                    const gmapsData = results[0];

                    // Définition des informations de l'adresse postale
                    const userZipCode = returnInfoAddress(gmapsData, 'postal_code');
                    const userLocality = returnInfoAddress(gmapsData, 'locality');
                    let departmentOriginal = returnInfoAddress(gmapsData, 'administrative_area_level_2');
                    const department = values.department;

                    const domTom: string[] = ['971', '972', '973', '974', '975', '976', '977', '978', '984', '986', '987', '988', '989'];
                    const checkDomTomZipCode = domTom.find((element: string) => element.includes(userZipCode.substring(0, 3)));

                    // Ajout d'un contrôle dans le cas d'un département vide
                    if (!departmentOriginal) {
                        departmentOriginal = returnInfoAddress(gmapsData, 'administrative_area_level_1');
                    }

                    // Si le département fait partie des DOM TOM, forcer le département
                    if (userZipCode.includes(checkDomTomZipCode)) {
                        departmentOriginal = checkDepartement(checkDomTomZipCode);
                    }

                    /**
                     * Injection des informations d'adresse dans le local storage
                     */
                    storageService.setAuditValue(1, isValid, 'userZipCode', userZipCode);
                    storageService.setAuditValue(1, isValid, 'userLocality', userLocality);
                    storageService.setAuditValue(1, isValid, 'departmentOriginal', departmentOriginal);
                    storageService.setAuditValue(1, isValid, 'department', userZipCode, departmentOriginal);

                    /**
                     * Mise à jour du formulaire
                     */
                    setValues({
                        ...values,
                        userZipCode,
                        userLocality,
                        departmentOriginal,
                        department,
                    });

                    /**
                     * Injection de la latitude et longitude dans le local storage
                     */
                    getLatLng(gmapsData).then((result) => {
                        storageService.checkPropertyExistThenCreateOrRender(1, 'userAddressLatitude', `Latitude`, PropertyType['basic']);
                        storageService.checkPropertyExistThenCreateOrRender(1, 'userAddressLongitude', `Longitude`, PropertyType['basic']);

                        storageService.setAuditValue(1, true, 'userAddressLatitude', result.lat);
                        storageService.setAuditValue(1, true, 'userAddressLongitude', result.lng);
                    });

                    const zipCodeTmp = parseInt(values.userZipCode).toString();
                    const checkZipCode = RegExp('^[0-9]{5}$').test(zipCodeTmp);

                    /**
                     * Cas où pas de code retourné par Google Maps et pas de code postal saisi manuellement
                     */
                    if (!userZipCode && !checkZipCode) {
                        AlertSwal.fire({
                            title: `Attention`,
                            text: `Le code postal n’a pu être déterminé à partir de votre saisie, essayez de le préciser.`,
                            width: 550,
                            confirmButtonText: 'Fermer',
                            focusConfirm: true,
                            customClass: {
                                confirmButton: 'btn btn-primary min-width',
                            },
                        });
                    }

                    /**
                     * Cas où pas de code retourné par Google Maps mais code postal saisi manuellement dans le champ
                     */
                    if (!userZipCode && checkZipCode) {
                        AlertSwal.fire({
                            title: `Attention`,
                            html: `Le code postal n’a pu être validé automatiquement à partir de votre saisie.<br/>Est-ce bien <code>${zipCodeTmp}</code> ?`,
                            width: 550,
                            confirmButtonText: 'Confirmer',
                            cancelButtonText: 'Modifier',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            reverseButtons: true,
                            showCancelButton: true,
                            focusCancel: true,
                            customClass: {
                                confirmButton: 'btn btn-primary min-width',
                                cancelButton: 'btn btn-red-border min-width',
                            },
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setValues({
                                    ...values,
                                    userZipCode: zipCodeTmp,
                                });
                                storageService.setAuditValue(1, isValid, 'userZipCode', zipCodeTmp);
                            }
                        });
                    }
                })
                .catch(() => {
                    AlertSwal.fire({
                        title: `Recherche de l'adresse`,
                        text: `Le code postal est introuvable, merci de remplir les informations manuellement`,
                        icon: 'error',
                        width: 550,
                        confirmButtonText: 'Fermer',
                        focusConfirm: true,
                        customClass: {
                            confirmButton: 'btn btn-primary min-width',
                        },
                    });
                });
        }
    };

    /**
     * Fonction pour mettre à jour un champ dans le local storage ainsi que dans le form
     * @param event (Event provenant de l'input)
     * @param handleChange (Event du formulaire pour mettre à jour une valeur)
     * @param isValid (Event du formulaire pour savoir s'il est valide)
     * @param step (étape)
     * @param propertyName (nom de la propriété)
     * @param propertyList (List des propriétés)
     */
    const updateValue = (event: any, handleChange: any, isValid: boolean, step: number, propertyName: string, propertyList?: any) => {
        // Obtention du type de variable pour écriture
        const type: any = (validationSchema as any).fields[propertyName].type;

        // Mise à jour du formulaire
        if (event.hasOwnProperty('target')) {
            handleChange(event);
        }

        // Cas spécial "children"
        if (propertyName === 'children') {
            return storageService.setAuditValue(1, isValid, propertyName, event.target.value, event.target.value);
        }

        // Mise à jour de la valeur du champ label dans le local storage
        if (propertyList) {
            const findLabel = propertyList.filter((property: any) => {
                if (event.hasOwnProperty('target')) {
                    return property.value === event.target.value;
                }
                return property.value === event.value;
            });
            return storageService.setAuditValue(1, isValid, propertyName, findLabel[0].value, findLabel[0].label);
        }

        // Cas standard
        return storageService.setAuditValue(1, isValid, propertyName, type === 'number' ? parseFloat(event.target.value) : event.target.value);
    };

    // const returnToHome = async (route: string) => {
    //     const { isConfirmed } = await AlertSwal.fire({
    //         title: 'Retour au menu principal',
    //         html: (
    //             <>
    //                 <p>En quittant cet écran, vous perdrez toutes les modifications effectuées sur cet écran.</p>
    //                 <p>Sélectionnez le bouton 'Etape suivante' pour les sauvegarder.</p>
    //             </>
    //         ),
    //         width: 550,
    //         confirmButtonText: 'Quitter cet écran',
    //         cancelButtonText: 'Annuler',
    //         showCancelButton: true,
    //         focusCancel: true,
    //         customClass: {
    //             cancelButton: 'btn btn-secondary min-width',
    //             confirmButton: 'btn btn-green min-width',
    //         },
    //     });

    //     if (isConfirmed) {
    //         if (!flowId) {
    //             storageService.setAudit(Template);
    //         } else {
    //             const flowDetail = await getFlow(flowId);
    //             storageService.setAudit(flowDetail.audit);
    //         }
    //         AlertSwal.close();
    //         push(route);
    //     }
    // };

    // /**
    //  * Fonction permettant la mise en local storage de la validité du formulaire + redirection
    //  * @param step (étape)
    //  * @param route (destination)
    //  */
    // const validateThenRoute = async (step: number, route: string) => {
    //     try {
    //         if (!flowId) {
    //             await apiParticulierService.createAuditFlow();
    //         }
    //         if (!auditSent) {
    //             // Mise à jour de l'audit en base
    //             await apiParticulierService.updateAuditDataService();
    //             // Mise à jour de la validité du formulaire dans le JSON pour cette étape précise
    //             storageService.setAuditStepValidity(1, true);
    //         }
    //         // Mise à jour de l'étape globale
    //         let globalCurrentStep = storageService.getAuditCurrentStep();
    //         if (step !== 8 && step === globalCurrentStep) {
    //             storageService.setAuditCurrentStep(globalCurrentStep + 1);
    //         }
    //         AlertSwal.close();
    //         push(route);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // };

    return (
        <Formik
            onSubmit={() => {}}
            initialValues={initialValues}
            isInitialValid={storageService.getAudit().step1.isValid}
            validateOnMount={true}
            validationSchema={validationSchema}
        >
            {({ values, errors, touched, handleChange, isValid, handleBlur, setFieldValue, setValues }) => {
                // Mise à jour validité Formik
                storageService.setAuditStepValidity(1, isValid);
                // Mise à jour de l'étape globale
                if (isValid) {
                    let globalCurrentStep = storageService.getAuditCurrentStep();
                    if (1 === globalCurrentStep) {
                        storageService.setAuditCurrentStep(globalCurrentStep + 1);
                    }
                }

                return (
                    <div className="container">
                        <h1 className="main-title-mini">1. Informations client</h1>
                        <h2 className="main-subtitle">Identification du client, contact et adresse</h2>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconClientInformations fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Informations du client</h2>
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="userMaritalStatus" className="form-label">
                                                Situation maritale<sup>*</sup>
                                            </label>
                                            <Select
                                                defaultValue={renderDefaultValueSelect(maritalStatusList, values.userMaritalStatus)}
                                                options={maritalStatusList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                isDisabled={auditSent}
                                                inputId="userMaritalStatus"
                                                onBlur={handleBlur}
                                                onChange={(event: any) => {
                                                    setValues({
                                                        ...values,
                                                        userMaritalStatus: event.value,
                                                    });
                                                    storageService.setAuditValue(1, isValid, 'userMaritalStatus', event.value);
                                                    if (['2', '3'].includes(event.value)) {
                                                        setFieldValue('partnerCivility', '');
                                                    }
                                                    if (['1'].includes(event.value)) {
                                                        setFieldValue('partnerCivility', '');
                                                        setFieldValue('partnerLastname', '');
                                                        setFieldValue('partnerFirstname', '');
                                                        setFieldValue('partnerBirthday', '');
                                                        setFieldValue('taxIncome2', null);
                                                        storageService.setAuditValue(1, isValid, 'partnerCivility', '');
                                                        storageService.setAuditValue(1, isValid, 'partnerLastname', '');
                                                        storageService.setAuditValue(1, isValid, 'partnerFirstname', '');
                                                        storageService.setAuditValue(1, isValid, 'partnerBirthday', '');
                                                        storageService.setAuditValue(1, isValid, 'taxIncome2', null);
                                                    }
                                                    if (['1', '2', '3'].includes(event.value)) {
                                                        setFieldValue('taxIncome2', null);
                                                        storageService.setAuditValue(1, isValid, 'taxIncome2', null);
                                                    }
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.userMaritalStatus ? ' filled' : '') +
                                                    (touched.userMaritalStatus && errors.userMaritalStatus ? ' invalid' : '') +
                                                    (!values.userMaritalStatus ? ' required' : '')
                                                }
                                                placeholder={'Choisissez un status marital'}
                                                styles={colourStyles}
                                            />
                                            {touched.userMaritalStatus && errors.userMaritalStatus && (
                                                <div className="invalid-feedback">{errors.userMaritalStatus}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="userCivility" className="form-label">
                                                Civilité déclarant 1 <small className="d-inline-flex">(Tel qu’écrit sur votre avis d’imposition)</small>
                                                <sup>*</sup>
                                            </label>
                                            <Select
                                                defaultValue={renderDefaultValueSelect(civilityList, values.userCivility)}
                                                options={civilityList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                isDisabled={auditSent}
                                                inputId="userCivility"
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    setValues({
                                                        ...values,
                                                        userCivility: event.value,
                                                    });
                                                    storageService.setAuditValue(1, isValid, 'userCivility', event.value);
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.userCivility ? ' filled' : '') +
                                                    (touched.userCivility && errors.userCivility ? ' invalid' : '') +
                                                    (!values.userCivility ? ' required' : '')
                                                }
                                                placeholder={'Choisissez la civilité'}
                                                styles={colourStyles}
                                            />
                                            {touched.userCivility && errors.userCivility && <div className="invalid-feedback">{errors.userCivility}</div>}
                                        </div>
                                        <div className="col-12 col-md-4 mb-3 mb-md-0 position-relative">
                                            <label htmlFor="userLastname" className="form-label">
                                                Nom déclarant 1<sup>*</sup>{' '}
                                                {storageService.getAuditCampagne() === 'action_logement' && (
                                                    <span className="surbrillance">(Celui qui travail dans le privé ou public de la liste ci-dessous)</span>
                                                )}
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="chrome-off"
                                                className={
                                                    'form-control' +
                                                    (values.userLastname ? ' filled' : '') +
                                                    (touched.userLastname && errors.userLastname ? ' invalid' : '') +
                                                    (!values.userLastname ? ' required' : '')
                                                }
                                                name="userLastname"
                                                id="userLastname"
                                                placeholder="Nom"
                                                maxLength={32}
                                                value={values.userLastname || ''}
                                                onBlur={handleBlur}
                                                onChange={(event) => updateValue(event, handleChange, isValid, 1, 'userLastname')}
                                            />
                                            {touched.userLastname && errors.userLastname && <div className="invalid-feedback">{errors.userLastname}</div>}
                                        </div>
                                        <div className="col-12 col-md-4 position-relative">
                                            <label htmlFor="userFirstname" className="form-label">
                                                Prénom déclarant 1<sup>*</sup>
                                                <span className="surbrillance">(Uniquement le premier prénom indiqué sur votre pièce d’identité)​</span>
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="chrome-off"
                                                className={
                                                    'form-control' +
                                                    (values.userFirstname ? ' filled' : '') +
                                                    (touched.userFirstname && errors.userFirstname ? ' invalid' : '') +
                                                    (!values.userFirstname ? ' required' : '')
                                                }
                                                name="userFirstname"
                                                id="userFirstname"
                                                placeholder="Prénom"
                                                maxLength={32}
                                                required
                                                onBlur={handleBlur}
                                                value={values.userFirstname || ''}
                                                onChange={(event) => updateValue(event, handleChange, isValid, 1, 'userFirstname')}
                                            />
                                            {touched.userFirstname && errors.userFirstname && <div className="invalid-feedback">{errors.userFirstname}</div>}
                                        </div>
                                    </div>
                                    {['2', '3', '4'].includes(values.userMaritalStatus) && (
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                <label htmlFor="partnerCivility" className="form-label">
                                                    Civilité déclarant 2 <small className="d-inline-flex">(Tel qu’écrit sur votre avis d’imposition)</small>
                                                    <sup>*</sup>
                                                </label>
                                                <Select
                                                    defaultValue={renderDefaultValueSelect(civilityList, values.partnerCivility)}
                                                    options={civilityList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    inputId="partnerCivility"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            partnerCivility: event.value,
                                                        });
                                                        storageService.setAuditValue(1, isValid, 'partnerCivility', event.value);
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.partnerCivility ? ' filled' : '') +
                                                        (touched.partnerCivility && errors.partnerCivility ? ' invalid' : '') +
                                                        (!values.partnerCivility ? ' required' : '')
                                                    }
                                                    placeholder={'Choisissez la civilité'}
                                                    styles={colourStyles}
                                                />
                                                {touched.partnerCivility && errors.partnerCivility && (
                                                    <div className="invalid-feedback">{errors.partnerCivility}</div>
                                                )}
                                            </div>
                                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                <label htmlFor="partnerLastname" className="form-label">
                                                    Nom déclarant 2<sup>*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    autoComplete="chrome-off"
                                                    className={
                                                        'form-control' +
                                                        (values.partnerLastname ? ' filled' : '') +
                                                        (touched.partnerLastname && errors.partnerLastname ? ' invalid' : '') +
                                                        (!values.partnerLastname ? ' required' : '')
                                                    }
                                                    placeholder="Nom"
                                                    name="partnerLastname"
                                                    id="partnerLastname"
                                                    onBlur={handleBlur}
                                                    value={values.partnerLastname || ''}
                                                    onChange={(event) => {
                                                        updateValue(event, handleChange, isValid, 1, 'partnerLastname');
                                                    }}
                                                />
                                                {touched.partnerLastname && errors.partnerLastname && (
                                                    <div className="invalid-feedback">{errors.partnerLastname}</div>
                                                )}
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <label htmlFor="partnerFirstname" className="form-label">
                                                    Prénom déclarant 2<sup>*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    autoComplete="chrome-off"
                                                    className={
                                                        'form-control' +
                                                        (values.partnerFirstname ? ' filled' : '') +
                                                        (touched.partnerFirstname && errors.partnerFirstname ? ' invalid' : '') +
                                                        (!values.partnerFirstname ? ' required' : '')
                                                    }
                                                    placeholder="Prénom"
                                                    name="partnerFirstname"
                                                    id="partnerFirstname"
                                                    required
                                                    onBlur={handleBlur}
                                                    value={values.partnerFirstname || ''}
                                                    onChange={(event) => {
                                                        updateValue(event, handleChange, isValid, 1, 'partnerFirstname');
                                                    }}
                                                />
                                                {touched.partnerFirstname && errors.partnerFirstname && (
                                                    <div className="invalid-feedback">{errors.partnerFirstname}</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="children" className="form-label">
                                                Nombre de personnes à charge<sup>*</sup>
                                            </label>
                                            <Select
                                                defaultValue={renderDefaultValueSelect(childrenList, values.children)}
                                                options={childrenList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                isDisabled={auditSent}
                                                inputId="children"
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    setValues({
                                                        ...values,
                                                        children: event.value,
                                                    });
                                                    storageService.setAuditValue(1, isValid, 'children', event.value, event.label);

                                                    /**
                                                     * Calcul de la longueur du tableau de "Reste à charge"
                                                     */
                                                    if (storageService.getAuditCampagne() === 'reste_a_charge') {
                                                        let arrayDependentLength = parseInt(event.value);

                                                        values.dependentDetails = [];
                                                        values.dependentDetails.length = 0;

                                                        for (let i = 0; i < arrayDependentLength; i++) {
                                                            values.dependentDetails = [
                                                                ...values.dependentDetails,
                                                                {
                                                                    dependentName: '',
                                                                    dependentFirstName: '',
                                                                    dependentAge: '',
                                                                },
                                                            ];
                                                        }
                                                        setValues({
                                                            ...values,
                                                            dependentDetails: values.dependentDetails,
                                                            children: event.value,
                                                        });
                                                        storageService.setAuditValue(1, isValid, 'dependentDetails', values.dependentDetails);
                                                    }
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.children ? ' filled' : '') +
                                                    (touched.children && errors.children ? ' invalid' : '') +
                                                    (!values.children ? ' required' : '')
                                                }
                                                placeholder={'0'}
                                                styles={colourStyles}
                                            />
                                            {touched.children && errors.children && <div className="invalid-feedback">{errors.children}</div>}
                                        </div>
                                    </div>

                                    {storageService.getAuditCampagne() === 'reste_a_charge' && (
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label className="form-label mb-0 mt-2">
                                                    Détails des personnes<sup>*</sup>
                                                </label>
                                                <div className="table-separate">
                                                    <table className="table table-client">
                                                        <thead>
                                                            <tr>
                                                                <th>Nom</th>
                                                                <th>Prénom</th>
                                                                <th>Date de naissance</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div className="table-fit">
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="chrome-off"
                                                                            className={
                                                                                'form-control' +
                                                                                (values.userLastname ? ' filled' : '') +
                                                                                (touched.userLastname && errors.userLastname ? ' invalid' : '') +
                                                                                (!values.userLastname ? ' required' : '')
                                                                            }
                                                                            name="userLastname"
                                                                            id="userLastname"
                                                                            placeholder="Nom"
                                                                            value={values.userLastname || ''}
                                                                            onBlur={handleBlur}
                                                                            onChange={(event) => updateValue(event, handleChange, isValid, 1, 'userLastname')}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table-fit">
                                                                        <input
                                                                            type="text"
                                                                            autoComplete="chrome-off"
                                                                            className={
                                                                                'form-control' +
                                                                                (values.userFirstname ? ' filled' : '') +
                                                                                (touched.userFirstname && errors.userFirstname ? ' invalid' : '') +
                                                                                (!values.userFirstname ? ' required' : '')
                                                                            }
                                                                            name="userFirstname"
                                                                            id="userFirstname"
                                                                            placeholder="Prénom"
                                                                            required
                                                                            onBlur={handleBlur}
                                                                            value={values.userFirstname || ''}
                                                                            onChange={(event) => updateValue(event, handleChange, isValid, 1, 'userFirstname')}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table-fit">
                                                                        <input
                                                                            type="date"
                                                                            className={
                                                                                'form-control' +
                                                                                (values.userBirthday ? ' filled' : '') +
                                                                                (touched.userBirthday && errors.userBirthday ? ' invalid' : '') +
                                                                                (!values.userBirthday ? ' required' : '')
                                                                            }
                                                                            name="userBirthday"
                                                                            id="userBirthday"
                                                                            placeholder="Date de naissance"
                                                                            required
                                                                            onBlur={handleBlur}
                                                                            value={values.userBirthday || ''}
                                                                            onChange={(event) => updateValue(event, handleChange, isValid, 1, 'userBirthday')}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr className="separator">
                                                                <td colSpan={3}>&nbsp;</td>
                                                            </tr>
                                                            {['2', '3', '4'].includes(values.userMaritalStatus) && (
                                                                <>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="table-fit">
                                                                                <input
                                                                                    type="text"
                                                                                    autoComplete="chrome-off"
                                                                                    className={
                                                                                        'form-control' +
                                                                                        (values.partnerLastname ? ' filled' : '') +
                                                                                        (touched.partnerLastname && errors.partnerLastname ? ' invalid' : '') +
                                                                                        (!values.partnerLastname ? ' required' : '')
                                                                                    }
                                                                                    placeholder="Nom"
                                                                                    name="partnerLastname"
                                                                                    id="partnerLastname"
                                                                                    onBlur={handleBlur}
                                                                                    value={values.partnerLastname || ''}
                                                                                    onChange={(event) =>
                                                                                        updateValue(event, handleChange, isValid, 1, 'partnerLastname')
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="table-fit">
                                                                                <input
                                                                                    type="text"
                                                                                    autoComplete="chrome-off"
                                                                                    className={
                                                                                        'form-control' +
                                                                                        (values.partnerFirstname ? ' filled' : '') +
                                                                                        (touched.partnerFirstname && errors.partnerFirstname
                                                                                            ? ' invalid'
                                                                                            : '') +
                                                                                        (!values.partnerFirstname ? ' required' : '')
                                                                                    }
                                                                                    placeholder="Prénom"
                                                                                    name="partnerFirstname"
                                                                                    id="partnerFirstname"
                                                                                    required
                                                                                    onBlur={handleBlur}
                                                                                    value={values.partnerFirstname || ''}
                                                                                    onChange={(event) =>
                                                                                        updateValue(event, handleChange, isValid, 1, 'partnerFirstname')
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="table-fit">
                                                                                <input
                                                                                    type="date"
                                                                                    className={
                                                                                        'form-control' +
                                                                                        (values.partnerBirthday ? ' filled' : '') +
                                                                                        (touched.partnerBirthday && errors.partnerBirthday ? ' invalid' : '') +
                                                                                        (!values.partnerBirthday ? ' required' : '')
                                                                                    }
                                                                                    name="partnerBirthday"
                                                                                    id="partnerBirthday"
                                                                                    placeholder="Date de naissance"
                                                                                    required
                                                                                    onBlur={handleBlur}
                                                                                    value={values.partnerBirthday || ''}
                                                                                    onChange={(event) =>
                                                                                        updateValue(event, handleChange, isValid, 1, 'partnerBirthday')
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="separator">
                                                                        <td colSpan={3}>&nbsp;</td>
                                                                    </tr>
                                                                </>
                                                            )}

                                                            {values.children !== '' && (
                                                                <>
                                                                    {Object.keys(values?.dependentDetails)
                                                                        .slice(0, 12)
                                                                        .map((item: any) => (
                                                                            <React.Fragment key={item}>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="table-fit">
                                                                                            <input
                                                                                                key={`dependentDetails__${values.dependentDetails[item].dependentName?.value}`}
                                                                                                type="text"
                                                                                                placeholder="Nom"
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.dependentDetails[item].dependentName
                                                                                                        ? ' filled'
                                                                                                        : '') +
                                                                                                    ((touched as any)['dependentName-' + item] &&
                                                                                                    !values.dependentDetails[item].dependentName
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.dependentDetails[item].dependentName
                                                                                                        ? ' required'
                                                                                                        : '')
                                                                                                }
                                                                                                disabled={auditSent}
                                                                                                onBlur={handleBlur}
                                                                                                id={'dependentName-' + item}
                                                                                                onChange={(event: any) => {
                                                                                                    let tmpdependentDetails = values.dependentDetails;
                                                                                                    tmpdependentDetails[item].dependentName =
                                                                                                        event.target.value;

                                                                                                    setValues({
                                                                                                        ...values,
                                                                                                        dependentDetails: tmpdependentDetails,
                                                                                                    });

                                                                                                    storageService.setAuditValue(
                                                                                                        1,
                                                                                                        isValid,
                                                                                                        'dependentDetails',
                                                                                                        tmpdependentDetails
                                                                                                    );
                                                                                                }}
                                                                                                value={values.dependentDetails[item].dependentName}
                                                                                            />
                                                                                        </div>
                                                                                    </td>

                                                                                    <td>
                                                                                        <div className="table-fit">
                                                                                            <input
                                                                                                key={`dependentDetails__${values.dependentDetails[item].dependentFirstName?.value}`}
                                                                                                type="text"
                                                                                                placeholder="Prénom"
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.dependentDetails[item].dependentFirstName
                                                                                                        ? ' filled'
                                                                                                        : '') +
                                                                                                    ((touched as any)['dependentFirstName-' + item] &&
                                                                                                    !values.dependentDetails[item].dependentFirstName
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.dependentDetails[item].dependentFirstName
                                                                                                        ? ' required'
                                                                                                        : '')
                                                                                                }
                                                                                                onBlur={handleBlur}
                                                                                                id={'dependentFirstName-' + item}
                                                                                                onChange={(event: any) => {
                                                                                                    let tmpdependentDetails = values.dependentDetails;
                                                                                                    tmpdependentDetails[item].dependentFirstName =
                                                                                                        event.target.value;

                                                                                                    setValues({
                                                                                                        ...values,
                                                                                                        dependentDetails: tmpdependentDetails,
                                                                                                    });

                                                                                                    storageService.setAuditValue(
                                                                                                        1,
                                                                                                        isValid,
                                                                                                        'dependentDetails',
                                                                                                        tmpdependentDetails
                                                                                                    );
                                                                                                }}
                                                                                                value={values.dependentDetails[item].dependentFirstName}
                                                                                            />
                                                                                        </div>
                                                                                    </td>

                                                                                    <td>
                                                                                        <div className="table-fit">
                                                                                            <input
                                                                                                key={`dependentDetails__${values.dependentDetails[item].dependentAge?.value}`}
                                                                                                type="date"
                                                                                                placeholder="Date de naissance"
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.dependentDetails[item].dependentAge
                                                                                                        ? ' filled'
                                                                                                        : '') +
                                                                                                    ((touched as any)['dependentAge-' + item] &&
                                                                                                    !values.dependentDetails[item].dependentAge
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.dependentDetails[item].dependentAge
                                                                                                        ? ' required'
                                                                                                        : '')
                                                                                                }
                                                                                                onBlur={handleBlur}
                                                                                                id={'dependentAge-' + item}
                                                                                                onChange={(event: any) => {
                                                                                                    let tmpdependentDetails = values.dependentDetails;
                                                                                                    tmpdependentDetails[item].dependentAge = event.target.value;

                                                                                                    setValues({
                                                                                                        ...values,
                                                                                                        dependentDetails: tmpdependentDetails,
                                                                                                    });

                                                                                                    storageService.setAuditValue(
                                                                                                        1,
                                                                                                        isValid,
                                                                                                        'dependentDetails',
                                                                                                        tmpdependentDetails
                                                                                                    );
                                                                                                }}
                                                                                                value={values.dependentDetails[item].dependentAge}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr className="separator">
                                                                                    <td colSpan={3}>&nbsp;</td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))}
                                                                </>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="userHomeTelephone" className="form-label">
                                                Téléphone fixe
                                            </label>
                                            <input
                                                type="text"
                                                className={
                                                    'form-control' +
                                                    (touched.userHomeTelephone && errors.userHomeTelephone ? ' invalid' : '') +
                                                    (values.userHomeTelephone ? ' filled' : '')
                                                }
                                                placeholder="Téléphone fixe"
                                                autoComplete="chrome-off"
                                                name="userHomeTelephone"
                                                id="userHomeTelephone"
                                                value={values.userHomeTelephone || ''}
                                                onBlur={handleBlur}
                                                onChange={(event) => updateValue(event, handleChange, isValid, 1, 'userHomeTelephone')}
                                            />
                                            {touched.userHomeTelephone && errors.userHomeTelephone && (
                                                <div className="invalid-feedback">{errors.userHomeTelephone}</div>
                                            )}
                                        </div>
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="userTelephone" className="form-label">
                                                Téléphone mobile<sup>*</sup>
                                            </label>
                                            <input
                                                type="text"
                                                className={
                                                    'form-control' +
                                                    (values.userTelephone ? ' filled' : '') +
                                                    (touched.userTelephone && errors.userTelephone ? ' invalid' : '') +
                                                    (!values.userTelephone ? ' required' : '')
                                                }
                                                placeholder="Téléphone mobile"
                                                autoComplete="chrome-off"
                                                name="userTelephone"
                                                id="userTelephone"
                                                value={values.userTelephone || ''}
                                                onBlur={handleBlur}
                                                onChange={(event) => updateValue(event, handleChange, isValid, 1, 'userTelephone')}
                                            />
                                            {touched.userTelephone && errors.userTelephone && <div className="invalid-feedback">{errors.userTelephone}</div>}
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label htmlFor="userEmail" className="form-label">
                                                Courriel<sup>*</sup>
                                            </label>
                                            <input
                                                type="email"
                                                className={
                                                    'form-control' +
                                                    (values.userEmail ? ' filled' : '') +
                                                    (touched.userEmail && errors.userEmail ? ' invalid' : '') +
                                                    (!values.userEmail ? ' required' : '')
                                                }
                                                placeholder="Courriel"
                                                autoComplete="chrome-off"
                                                name="userEmail"
                                                id="userEmail"
                                                required
                                                onBlur={(event) => {
                                                    if (event.target.value !== '') {
                                                        if (values.maPrimeRenovLogin === '' && !errors.userEmail && !storageService.getAuditCampagne()) {
                                                            // Mise à jour du champ Login & mot de passe maPrimeRenov'
                                                            setValues({
                                                                ...values,
                                                                maPrimeRenovLogin: event.target.value,
                                                            });
                                                            storageService.setAuditValue(1, true, 'maPrimeRenovLogin', event.target.value);
                                                        }
                                                    }
                                                    handleBlur(event);
                                                }}
                                                value={values.userEmail || ''}
                                                onChange={(event) => updateValue(event, handleChange, isValid, 1, 'userEmail')}
                                            />
                                            {touched.userEmail && errors.userEmail && <div className="invalid-feedback">{errors.userEmail}</div>}
                                        </div>
                                    </div>
                                    {storageService.getAuditCampagne() === 'action_logement' && (
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4">
                                                <label htmlFor="contractType" className="form-label">
                                                    Type de contrat<sup>*</sup>
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(contractTypeList, values.contractType)}
                                                    options={contractTypeList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent || values.employerType === '3' || values.employerType === '4'}
                                                    inputId="contractType"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            contractType: event.value,
                                                        });

                                                        storageService.setAuditValue(1, isValid, 'contractType', event.value, event.label);

                                                        if (['3', '4'].includes(event.value)) {
                                                            setValues({
                                                                ...values,
                                                                contractType: event.value,
                                                                employerType: '',
                                                            });

                                                            storageService.setAuditValue(1, isValid, 'contractType', event.value, event.label);
                                                            storageService.removeAuditValue(1, 'employerType', true);
                                                        }
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.contractType ? ' filled' : '') +
                                                        (touched.contractType && errors.contractType ? ' invalid' : '') +
                                                        (!values.contractType ? ' required' : '')
                                                    }
                                                    placeholder={'CDI'}
                                                    styles={colourStyles}
                                                />
                                                {touched.contractType && errors.contractType && <div className="invalid-feedback">{errors.contractType}</div>}
                                            </div>

                                            {['0', '1'].includes(values.contractType) && (
                                                <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                    <label htmlFor="employerType" className="form-label">
                                                        Type d'employeur<sup>*</sup>
                                                    </label>
                                                    <Select
                                                        value={renderDefaultValueSelect(employerTypeList, values.employerType)}
                                                        options={employerTypeList}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        isMulti={false}
                                                        isDisabled={auditSent}
                                                        inputId="employerType"
                                                        onBlur={handleBlur}
                                                        onChange={(event) => {
                                                            setValues({
                                                                ...values,
                                                                employerType: event.value,
                                                            });

                                                            storageService.setAuditValue(1, isValid, 'employerType', event.value, event.label);

                                                            if (event.value !== '1') {
                                                                setValues({
                                                                    ...values,
                                                                    employerType: event.value,
                                                                    publicCompany: '',
                                                                });

                                                                storageService.removeAuditValue(1, 'publicCompany', true);
                                                                storageService.setAuditValue(1, isValid, 'employerType', event.value, event.label);
                                                            }
                                                        }}
                                                        className={
                                                            'basic-single' +
                                                            (values.employerType ? ' filled' : '') +
                                                            (touched.employerType && errors.employerType ? ' invalid' : '') +
                                                            (!values.employerType ? ' required' : '')
                                                        }
                                                        placeholder={'Entreprise publique'}
                                                        styles={colourStyles}
                                                    />
                                                    {touched.employerType && errors.employerType && (
                                                        <div className="invalid-feedback">{errors.employerType}</div>
                                                    )}
                                                </div>
                                            )}

                                            {values.employerType === '1' && (
                                                <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                    <label htmlFor="publicCompany" className="form-label">
                                                        Entreprise publique<sup>*</sup>
                                                    </label>
                                                    <Select
                                                        value={{
                                                            value: storageService.getAudit().step1.publicCompany.value,
                                                            label: storageService.getAudit().step1.publicCompany.value_label,
                                                        }}
                                                        options={publicCompanyList}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        isMulti={false}
                                                        isDisabled={auditSent}
                                                        inputId={'publicCompany'}
                                                        onChange={(event: any) => {
                                                            setValues({
                                                                ...values,
                                                                publicCompany: event.value,
                                                            });

                                                            storageService.setAuditValue(1, isValid, 'publicCompany', event.value, event.label);
                                                        }}
                                                        className={
                                                            'basic-single' +
                                                            (values.publicCompany ? ' filled' : '') +
                                                            (touched.publicCompany && errors.publicCompany ? ' invalid' : '') +
                                                            (!values.publicCompany ? ' required' : '')
                                                        }
                                                        placeholder={'Entreprise publique'}
                                                        styles={colourStyles}
                                                    />
                                                    {touched.publicCompany && errors.publicCompany && (
                                                        <div className="invalid-feedback">{errors.publicCompany}</div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className={values.userMaritalStatus === '4' ? 'col-12 col-md-6 mb-3' : 'col-12 col-md-6 mb-3 mb-md-0'}>
                                            <label htmlFor="taxIncome" className="form-label">
                                                Revenu fiscal{' '}
                                                <small className="d-inline-flex">
                                                    ({['4'].includes(values.userMaritalStatus) ? 'déclarant 1' : 'du foyer'})
                                                </small>
                                                <sup>*</sup>
                                            </label>
                                            <div className="input-group">
                                                <input
                                                    type="number"
                                                    min={0}
                                                    className={
                                                        'form-control' +
                                                        (touched.taxIncome && errors.taxIncome ? ' invalid' : '') +
                                                        (values.taxIncome || values.taxIncome === 0 ? ' filled' : '') +
                                                        (!values.taxIncome ? ' required' : '')
                                                    }
                                                    placeholder="24000"
                                                    id="taxIncome"
                                                    onBlur={handleBlur}
                                                    defaultValue={values.taxIncome}
                                                    onChange={(event) => updateValue(event, handleChange, isValid, 1, 'taxIncome')}
                                                    onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                />
                                                <span className="input-group-text">€</span>
                                            </div>
                                            {touched.taxIncome && errors.taxIncome && <div className="invalid-feedback">{errors.taxIncome}</div>}
                                        </div>
                                        {values.userMaritalStatus === '4' && (
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <label htmlFor="taxIncome2" className="form-label">
                                                    Revenu fiscal <small className="d-inline-flex">(déclarant 2)</small>
                                                    <sup>*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className={
                                                            'form-control' +
                                                            (touched.taxIncome2 && errors.taxIncome2 ? ' invalid' : '') +
                                                            (values.taxIncome2 || values.taxIncome2 === 0 ? ' filled' : '') +
                                                            (!values.taxIncome2 ? ' required' : '')
                                                        }
                                                        placeholder="24000"
                                                        id="taxIncome2"
                                                        onBlur={handleBlur}
                                                        defaultValue={values.taxIncome2}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 1, 'taxIncome2')}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">€</span>
                                                </div>
                                                {touched.taxIncome2 && errors.taxIncome2 && <div className="invalid-feedback">{errors.taxIncome2}</div>}
                                            </div>
                                        )}
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="taxUsed" className="form-label">
                                                Dépenses C.I ou Aides perçues Maprimerenov’
                                            </label>
                                            <div className="input-group">
                                                <input
                                                    type="number"
                                                    className={
                                                        'form-control' +
                                                        (touched.taxUsed && errors.taxUsed ? ' invalid' : '') +
                                                        (values.taxUsed ? ' filled' : '')
                                                    }
                                                    placeholder="24000"
                                                    min={0}
                                                    id="taxUsed"
                                                    onBlur={handleBlur}
                                                    defaultValue={values.taxUsed}
                                                    onChange={(event) => updateValue(event, handleChange, isValid, 1, 'taxUsed')}
                                                    onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                />
                                                <span className="input-group-text">€</span>
                                            </div>
                                            {touched.taxUsed && errors.taxUsed && <div className="invalid-feedback">{errors.taxUsed}</div>}
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconAddress fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Adresse du client</h2>
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label htmlFor="userAddressAutoComplete" className="form-label">
                                                Adresse du client (remplissage automatique)<sup>*</sup>
                                            </label>
                                            <PlacesAutocomplete
                                                value={infoAddress?.address || ''}
                                                onChange={(address: string) => handleChangeAddress(address)}
                                                onSelect={(address: string, placeID: string) => {
                                                    handleSelectAddress(placeID, setValues, values);
                                                }}
                                                searchOptions={searchOptions}
                                            >
                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div className="position-relative">
                                                        <input
                                                            {...getInputProps({
                                                                type: 'text',
                                                                autoComplete: 'chrome-off',
                                                                name: 'userAddressAutoComplete',
                                                                id: 'userAddressAutoComplete',
                                                                placeholder: '123 Rue du puits, Paris, France',
                                                                className:
                                                                    'form-control ' +
                                                                    (!infoAddress?.address ? 'required' : '') +
                                                                    (infoAddress?.address ? 'filled' : ''),
                                                            })}
                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Chargement...</div>}
                                                            {suggestions.map((suggestion: Suggestion, key: number) => {
                                                                const style = suggestion.active
                                                                    ? {
                                                                          backgroundColor: '#76ca4f',
                                                                          cursor: 'pointer',
                                                                          color: '#ffffff',
                                                                          padding: '0.5rem',
                                                                          borderRadius: '5px',
                                                                      }
                                                                    : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '0.5rem' };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            style,
                                                                        })}
                                                                        key={key}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </div>
                                    </div>
                                    <div className="card background-grey">
                                        <div className="card-body">
                                            <div className="mb-3 form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="userAddressManual"
                                                    value={values.userAddressManual || ''}
                                                    defaultChecked={values.userAddressManual}
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        storageService.setAuditValue(
                                                            1,
                                                            isValid,
                                                            'userAddressManual',
                                                            !storageService.getAudit().step1.userAddressManual.value
                                                        );
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="userAddressManual">
                                                    Je ne trouve pas l'adresse ou je souhaite modifier certaines informations
                                                </label>
                                            </div>
                                            <fieldset disabled={!storageService.getAudit().step1.userAddressManual.value}>
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="userAddress" className="form-label">
                                                            Adresse<sup>*</sup>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (touched.userAddress && errors.userAddress ? ' invalid' : '') +
                                                                (values.userAddress ? ' filled' : '') +
                                                                (!values.userAddress ? ' required' : '')
                                                            }
                                                            placeholder="123 Rue du puit"
                                                            id="userAddress"
                                                            required
                                                            onBlur={handleBlur}
                                                            value={values.userAddress || ''}
                                                            onChange={(event) => updateValue(event, handleChange, isValid, 1, 'userAddress')}
                                                        />
                                                        {touched.userAddress && errors.userAddress && (
                                                            <div className="invalid-feedback">{errors.userAddress}</div>
                                                        )}
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="userZipCode" className="form-label">
                                                            Code postal (et/ou ville)<sup>*</sup>
                                                        </label>
                                                        <PlacesAutocomplete
                                                            value={values.userZipCode || ''}
                                                            onChange={(value: string) => handleChangePostalCode(value, setValues, values)}
                                                            onSelect={(address: string, placeID: string) =>
                                                                handlePostalCodeLocality(placeID, setValues, values, isValid)
                                                            }
                                                            searchOptions={searchOptionsPostalCode}
                                                        >
                                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                <div className="position-relative">
                                                                    <input
                                                                        {...getInputProps({
                                                                            type: 'text',
                                                                            id: 'userZipCode',
                                                                            placeholder: '75019',
                                                                            className:
                                                                                'form-control' +
                                                                                (touched.userZipCode && errors.userZipCode ? ' invalid' : '') +
                                                                                (values.userZipCode ? ' filled' : '') +
                                                                                (!values.userZipCode ? ' required' : ''),
                                                                        })}
                                                                    />
                                                                    <div className="autocomplete-dropdown-container">
                                                                        {loading && <div>Chargement...</div>}
                                                                        {suggestions.map((suggestion, key) => {
                                                                            const style = suggestion.active
                                                                                ? {
                                                                                      backgroundColor: '#76ca4f',
                                                                                      cursor: 'pointer',
                                                                                      color: '#ffffff',
                                                                                      padding: '0.5rem',
                                                                                      borderRadius: '5px',
                                                                                  }
                                                                                : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '0.5rem' };
                                                                            return (
                                                                                <div
                                                                                    {...getSuggestionItemProps(suggestion, {
                                                                                        style,
                                                                                    })}
                                                                                    key={key}
                                                                                >
                                                                                    <span>{suggestion.description}</span>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </PlacesAutocomplete>
                                                        {touched.userZipCode && errors.userZipCode && (
                                                            <div className="invalid-feedback">{errors.userZipCode}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="userLocality" className="form-label">
                                                            Ville<sup>*</sup>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (touched.userLocality && errors.userLocality ? ' invalid' : '') +
                                                                (values.userLocality ? ' filled' : '') +
                                                                (!values.userLocality ? ' required' : '')
                                                            }
                                                            placeholder="Paris"
                                                            id="userLocality"
                                                            name="userLocality"
                                                            required
                                                            readOnly={!values.userAddressManual}
                                                            onBlur={handleBlur}
                                                            value={values.userLocality || ''}
                                                            onChange={(event) => updateValue(event, handleChange, isValid, 1, 'userLocality')}
                                                        />
                                                        {/* {touched.userLocality && errors.userLocality &&
                                                        <div className="invalid-feedback">
                                                            {errors.userLocality}
                                                        </div>
                                                        } */}
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="departmentOriginal" className="form-label">
                                                            Département<sup>*</sup>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (touched.departmentOriginal && errors.departmentOriginal ? ' invalid' : '') +
                                                                (values.departmentOriginal ? ' filled' : '') +
                                                                (!values.departmentOriginal ? ' required' : '')
                                                            }
                                                            placeholder="Paris"
                                                            id="departmentOriginal"
                                                            name="departmentOriginal"
                                                            required
                                                            readOnly
                                                            onBlur={handleBlur}
                                                            value={values.departmentOriginal || ''}
                                                            onChange={(event: any) => {
                                                                updateValue(event, handleChange, isValid, 1, 'departmentOriginal');
                                                                storageService.setAuditValue(1, isValid, 'department', values.userZipCode, event.value);
                                                            }}
                                                        />
                                                        {touched.departmentOriginal && errors.departmentOriginal && (
                                                            <div className="invalid-feedback">{errors.departmentOriginal}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        {storageService.getAuditCampagne() !== 'action_logement' && (
                            <div className="row mb-5">
                                <div className="col-12 col-md-6 mb-5 mb-md-0">
                                    <div className="card h-100">
                                        <div className="card-title mini-card">
                                            <h3 className="title-green">Avis d'imposition</h3>
                                        </div>
                                        <div className="card-body mini-card">
                                            <fieldset className={showDeclarant2(values) ? 'row w-100' : 'w-100'} disabled={auditSent}>
                                                <div className={showDeclarant2(values) ? 'col-12 col-md-6 mb-3' : 'mb-3'}>
                                                    <label htmlFor="taxNoticeUserRefFiscal" className="form-label">
                                                        Numéro fiscal du déclarant {showDeclarant2(values) && '1'}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        maxLength={13}
                                                        className={
                                                            'form-control' +
                                                            (errors.taxNoticeUserRefFiscal ? ' invalid' : '') +
                                                            (values.taxNoticeUserRefFiscal ? ' filled' : '') +
                                                            (!values.taxNoticeUserRefFiscal ? ' required' : '')
                                                        }
                                                        onBlur={handleBlur}
                                                        autoComplete="chrome-off"
                                                        placeholder="01234567890"
                                                        name="taxNoticeUserRefFiscal"
                                                        id="taxNoticeUserRefFiscal"
                                                        value={values.taxNoticeUserRefFiscal}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 1, 'taxNoticeUserRefFiscal')}
                                                    />
                                                    {touched.taxNoticeUserRefFiscal && errors.taxNoticeUserRefFiscal && (
                                                        <div className="invalid-feedback">{errors.taxNoticeUserRefFiscal}</div>
                                                    )}
                                                </div>

                                                <div className={showDeclarant2(values) ? 'col-12 col-md-6 mb-3' : 'mb-3'}>
                                                    <label htmlFor="taxNoticeNoAvis" className="form-label">
                                                        Référence de l'avis fiscal {showDeclarant2(values) && 'déclarant 1'}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        maxLength={13}
                                                        className={
                                                            'form-control' +
                                                            (errors.taxNoticeNoAvis ? ' invalid' : '') +
                                                            (values.taxNoticeNoAvis ? ' filled' : '') +
                                                            (!values.taxNoticeNoAvis ? ' required' : '')
                                                        }
                                                        onBlur={handleBlur}
                                                        autoComplete="chrome-off"
                                                        placeholder="1234567890123"
                                                        name="taxNoticeNoAvis"
                                                        id="taxNoticeNoAvis"
                                                        value={values.taxNoticeNoAvis}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 1, 'taxNoticeNoAvis')}
                                                    />
                                                    {touched.taxNoticeNoAvis && errors.taxNoticeNoAvis && (
                                                        <div className="invalid-feedback">{errors.taxNoticeNoAvis}</div>
                                                    )}
                                                </div>

                                                {showDeclarant2(values) && (
                                                    <>
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <label htmlFor="taxNoticeUserRefFiscalTwo" className="form-label">
                                                                Numéro fiscal du déclarant 2
                                                            </label>
                                                            <input
                                                                type="text"
                                                                maxLength={13}
                                                                className={
                                                                    'form-control' +
                                                                    (errors.taxNoticeUserRefFiscalTwo ? ' invalid' : '') +
                                                                    (values.taxNoticeUserRefFiscalTwo ? ' filled' : '') +
                                                                    (!values.taxNoticeUserRefFiscalTwo ? ' required' : '')
                                                                }
                                                                onBlur={handleBlur}
                                                                autoComplete="chrome-off"
                                                                placeholder="01234567890"
                                                                name="taxNoticeUserRefFiscalTwo"
                                                                id="taxNoticeUserRefFiscalTwo"
                                                                value={values.taxNoticeUserRefFiscalTwo}
                                                                onChange={(event) => updateValue(event, handleChange, isValid, 1, 'taxNoticeUserRefFiscalTwo')}
                                                            />

                                                            {touched.taxNoticeUserRefFiscalTwo && errors.taxNoticeUserRefFiscalTwo && (
                                                                <div className="invalid-feedback">{errors.taxNoticeUserRefFiscalTwo}</div>
                                                            )}
                                                        </div>

                                                        <div className="col-12 col-md-6 mb-3">
                                                            <label htmlFor="taxNoticeNoAvisTwo" className="form-label">
                                                                Référence de l'avis fiscal déclarant 2
                                                            </label>
                                                            <input
                                                                type="text"
                                                                maxLength={13}
                                                                className={
                                                                    'form-control' +
                                                                    (errors.taxNoticeNoAvisTwo ? ' invalid' : '') +
                                                                    (values.taxNoticeNoAvisTwo ? ' filled' : '') +
                                                                    (!values.taxNoticeNoAvisTwo ? ' required' : '')
                                                                }
                                                                onBlur={handleBlur}
                                                                autoComplete="chrome-off"
                                                                placeholder="1234567890123"
                                                                name="taxNoticeNoAvisTwo"
                                                                id="taxNoticeNoAvisTwo"
                                                                value={values.taxNoticeNoAvisTwo}
                                                                onChange={(event) => updateValue(event, handleChange, isValid, 1, 'taxNoticeNoAvisTwo')}
                                                            />
                                                            {touched.taxNoticeNoAvisTwo && errors.taxNoticeNoAvisTwo && (
                                                                <div className="invalid-feedback">{errors.taxNoticeNoAvisTwo}</div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>

                                {!storageService.getAuditCampagne() && (
                                    <div className="col-12 col-md-6">
                                        <div className="card background-green h-100">
                                            <div className="card-title mini-card">
                                                <h3 className="title-white">MAPRIMERENOV'</h3>
                                            </div>
                                            <div className="card-body mini-card">
                                                <fieldset className="w-100" disabled={auditSent}>
                                                    <div className="mb-3">
                                                        <label htmlFor="maPrimeRenovLogin" className="form-label text-white">
                                                            Identifiant
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={
                                                                'form-control' +
                                                                (errors.maPrimeRenovLogin ? ' invalid' : '') +
                                                                (values.maPrimeRenovLogin ? ' filled' : '')
                                                            }
                                                            onBlur={handleBlur}
                                                            autoComplete="chrome-off"
                                                            placeholder="Adresse courriel ou identifiant"
                                                            id="maPrimeRenovLogin"
                                                            value={values.maPrimeRenovLogin || ''}
                                                            onChange={(event) => updateValue(event, handleChange, isValid, 1, 'maPrimeRenovLogin')}
                                                        />
                                                        {errors.maPrimeRenovLogin && <div className="invalid-feedback">{errors.maPrimeRenovLogin}</div>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="maPrimeRenovPassword" className="form-label text-white">
                                                            Mot de passe
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                type={showPassword ? `text` : `password`}
                                                                className={
                                                                    'form-control' +
                                                                    (errors.maPrimeRenovPassword ? ' invalid' : '') +
                                                                    (values.maPrimeRenovPassword ? ' filled' : '')
                                                                }
                                                                onBlur={handleBlur}
                                                                autoComplete="chrome-off"
                                                                placeholder="Mot de passe"
                                                                id="maPrimeRenovPassword"
                                                                value={values.maPrimeRenovPassword || ''}
                                                                onChange={(event) => updateValue(event, handleChange, isValid, 1, 'maPrimeRenovPassword')}
                                                            />
                                                            <button className="btn icon-show-password" onClick={() => setShowPassword(!showPassword)}>
                                                                &nbsp;
                                                            </button>
                                                        </div>
                                                        {errors.maPrimeRenovPassword && <div className="invalid-feedback">{errors.maPrimeRenovPassword}</div>}
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="maPrimeRenovCheck"
                                                            value={values.maPrimeRenovCheck || ''}
                                                            defaultChecked={values.maPrimeRenovCheck}
                                                            onChange={(event) => {
                                                                handleChange(event);
                                                                storageService.setAuditValue(
                                                                    1,
                                                                    isValid,
                                                                    'maPrimeRenovCheck',
                                                                    !storageService.getAudit().step1.maPrimeRenovCheck.value
                                                                );
                                                            }}
                                                        />
                                                        <label className="form-check-label text-white" htmlFor="maPrimeRenovCheck">
                                                            Le compte maPrimeRenov' a déjà été créé
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="navigation text-center">
                            <button type="button" className={'btn btn-secondary maxi-min-width mx-2 mb-3'} onClick={() => returnToHome(1, '/audit')}>
                                Retour au menu principal
                            </button>
                            <button
                                type="button"
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    if (Object.entries(errors).length !== 0) {
                                        // Préparation liste d'étapes en erreur
                                        let errorsList: string[] = [];
                                        Object.keys(errors).forEach((field: any) => {
                                            // @ts-ignore
                                            if (typeof errors[field] === 'object') {
                                                // @ts-ignore
                                                errors[field].forEach((element: any) => {
                                                    for (let key in element) {
                                                        if (typeof element[key] === 'object') {
                                                            errorsList = [...errorsList, element[key].value];
                                                        } else {
                                                            errorsList = [...errorsList, element[key]];
                                                        }
                                                    }
                                                });
                                            } else {
                                                // @ts-ignore
                                                errorsList = [...errorsList, `${errors[field]}`];
                                            }
                                        });
                                        // Affichage message
                                        AlertSwal.fire({
                                            title: `Champs manquants`,
                                            html: `<p class="mb-0">Vous ne pouvez pas valider l’étape en cours car certains champs n’ont pas été saisis ou sont en erreur:<br/>
                                    <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span></p>`,
                                            cancelButtonText: 'Continuer la saisie',
                                            showConfirmButton: false,
                                            showCancelButton: true,
                                            focusCancel: true,
                                            showClass: {
                                                popup: 'errors',
                                                backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                            },
                                            customClass: {
                                                cancelButton: 'btn btn-primary maxi-min-width',
                                            },
                                        });
                                    } else {
                                        validateThenRoute(1, ROUTE_AUDIT_INFO_HABITATION);
                                    }
                                }}
                                className={'btn btn-green maxi-min-width mx-2 mb-3'}
                            >
                                Étape suivante
                            </button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default ClientInformations;
